.container {
  .auth-success-block {
    border-bottom: none !important;
  }
  .action_block {
    border-top: 2px solid #d3d4d7;
  }
  .sub-title {
    margin-bottom: unset;
  }
  .info_action_btn {
    width: 300px;
  }
}