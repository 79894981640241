@import '../common/animations'; 

.marketing-banner {
  position: relative;
  margin-top: 8px;
  height: 660px;
  display: flex;
  flex-direction: column;
  padding: 26px 188px;
  background-image: url('../../assets/marketing-banner-2.png');
  background-repeat: no-repeat;
  background-position: center top -190px;
  background-color: #507B95;
  background-size: 158%;
  @media screen and (min-width: 1660px) {
    height: 840px;
  }
  .absolute-transparent-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
  }

  #background-video {
    position: absolute;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -1;
    background-size: cover;
  }

  .banner-content {
    z-index: 2;
    color: white;
    display: flex;
    flex-direction: column;
    row-gap: 2px;

    .current-year {
      font-size: 46px;
      font-weight: 700;
    }

    .banner-desc {
      font-size: 16px;
      font-weight: 300;
      line-height: 19px;
      width: 550px;
    }

    h1 {
     font-size: 34px;
    }
  }

  .intro-button {
    background-color: #EE9A1C;
    color: white;
    border: none;
    width: 216px;
    height: 51px;
    border-radius: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 6px;
    margin-top: 8px;
    cursor: pointer;
  }
}

.marketing-landing-content {
  border-radius: 60px 266px 0 0;
  margin-top: -250px;
  background: white;
  z-index: 99;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  clip-path: polygon(-16% 10%, 13% 1%, 100% 0%, 100% 100%, 0% 100%);
  @media screen and (min-width: 1501px) {
    clip-path: polygon(-16% 10%, 23% 1%, 100% -5%, 100% 100%, 0% 100%)
  }

  @media screen and (max-width: 1501px) {
    clip-path: polygon(-16% 10%, 17% 1%, 100% 0%, 100% 100%, 0% 100%);
  }
  @media screen and (min-width: 1600px) and (max-width: 1659px) {
    border-radius: 60px 350px 0 0;
  }
  @media screen and (min-width: 1660px) {
    margin-top: -427px;
    border-radius: 60px 452px 0 0;
  }
  .search-input-container {
    padding-right: 11px;
  }

  .not_found_block {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 38px;
    p {
      color: #999999;
      font-size: 20px;
      font-weight: 400;
      margin-bottom: unset;
    }
  }

  .courses-block {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 24px;
    margin-top: 20px;
    align-items: start;
  }
  
  .courses-block::after {
    content: '';
    width: 100%;
    grid-column: 1 / -1;
    height: 0;
  }
}

.courses-counts-block {
  box-shadow: 0px 4px 20px 0px #DDE4FF;
  background-color: #ffffff;
  border-radius: 15px;
  max-width: 1064px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 24px 32px;
  width: fit-content;
  margin-top: 106px;
  z-index: 999;
  width: -webkit-fill-available;
  margin-left: auto;
  margin-right: auto;

  .course-count-item {
    height: 109px;
    width: 238px;
    box-shadow: 0px 1.547445297241211px 7.737226486206055px 0px #DDE4FF;
    background-color: #EFF8FF;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 12px 27.5px;
    min-width: 238px;
    cursor: pointer;
    
    .title {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #107CAA;
      font-size: 18px;
      font-weight: 700;
    }
    .description {
      font-size: 12px;
      font-weight: 400;
      color: #4A5771;
      text-align: center;
    }

    &:hover {
      background-color: #131F6B;
      text-decoration: none;
      .title {
        color: #FFFFFF;
      }
      .description {
        color: #B5BEF6;
      }
    }
  }
}

.courses-container {
  width: 100%;
  padding: 32px 100px;
  background-color: #F0F3F5;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  
  .title {
    color: #222222;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 24px;
  }
}

.search-block {
  display: flex;
  gap: 20px;
}

.search-types-block {
  display: flex;
  gap: 12px;
  overflow: auto;

  .search-type-item {
    font-size: 10px;
    font-weight: 500;
    border-radius: 5px;
    padding: 10px 24px;
    color: #95989A;
    background-color: #ffffff;
    cursor: pointer;

    &.active {
      order: -1;
      color: #ffffff;
      background-color: #131F6B;
    }
    &.first {
      order: -2;
    }
  }
  .courses-block {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 24px;
    margin-top: 20px;
    min-height: 640px;
  }
}
.marketing-course-item {
  width: 295px;
  height: 312px;
  color: black !important;
  text-decoration: none;
  margin: auto;
  .img_block {
    overflow: hidden;
    border-radius: 5px;
  }
  .title-block {
    display: flex;
    justify-content: space-between;
    margin-top: 4px;
    margin-bottom: 6px;
    .marketing-course-title {
      color: #13387C;
      font-weight: 700;
      font-size: 20px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .create-date {
      color: #4A5771;
      font-weight: 400;
      font-size: 13px;
    }
  }
  .course-description {
    color: #4A5771;
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
    text-align: start;
  }
  .img_block {
    width: 100%;
    height: 205px;
    background-color: #ffffff;
    .course-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}


.landing-pagination {
  display: flex;
  list-style-type: none;
  justify-content: center;
  padding: 15px 0;

  .previous, .next {
    display: none;
  }

  li {
    margin: 0 5px;
    
    a {
      width: 32px !important;
      height: 32px !important;
      cursor: pointer;
      background-color: #ffffff !important;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #FFFFFF;

      &:hover {
        background-color: #131F6B !important;
        color: white !important;
      }
    }

    &.active {
      a {
        background-color: #131F6B !important;
        color: white !important;
      }
    }

    &.disabled a {
      color: #aaa; // Adjust as needed
    }

    &.break-me a {
      // Styling for the break label ...
    }
  }
}

.course-item {
  // Styles for course items
  .course-clock-icon {
    // Style for the clock icon
  }
  // ... other styles for course content
}


.presentation-container {
  background-color: #F0F3F5;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .presentation-item {
    width: 100%;
    height: 386px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 82px;

    .description-sec {
      position: relative;
      max-width: 540px;
      max-height: 342px;
      background: transparent;
      &.icon-top-left {
        padding-top: 26px;
        padding-left: 48px;
      }
      &.icon-bottom-right {
        padding-bottom: 26px;
        padding-right: 48px;
      }
      .icon-top-left {
        position: absolute;
        top: 0;
        left: 0;
      }
      .icon-bottom-right {
        position: absolute;
        bottom: 0;
        right: 0;
      }
      

      .inner-block {
        position: relative;
        background-color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        box-shadow: 0px 0px 30px 0px #2573E826;
        padding: 68px 32px;
        border-radius: 12px;
        *{
          text-align: center;
        }
        .title {
          color: #073A9F;
          font-size: 24px;
          font-weight: 400;
        }
        .desc {
          color: #111827;
          font-size: 18px;
          font-weight: 400;
        }
        .learn-more-btn {
          background-color: #A245AD;
          border-radius: 10px;
          padding: 12px 49px;
          font-size: 16px;
          font-weight: 500;
          color: #ffffff;
        }
      }
    }

    .image-sec {
      position: relative;
      width: 600px;
      height: 328px;
      border-radius: 12px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .play-icon {
        position: absolute;
        top: calc(50% - 29.5px);
        left: calc(50% - 29.5px);
        cursor: pointer;
        
        .play-svg {
          transition: .5s;
        }

        .play-svg:hover {
          transform: scale(1.2);
        }
      }
    }
  }
}

.marketing-footer {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  width: 100%;

  .top-orange-line {
    width: 100%;
    height: 8px;
    background-color: #EE9A1C;
  }
  .main-footer {
    min-height: 301px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 84px;
    background-color: #F0F3F5;
    color: #ffffff;
    margin-top: 6px;
    padding: 40px;
    .column-group {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .column-group {
        margin-bottom: 57px;
      }
    }
    .left-col {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .horizontal-col-group {
        display: flex;
        gap: 40px;
      }
    }
    .right-col {
      display: flex;
      gap: 160px;
    }
    .col-title {
      margin-bottom: 12px;
    }
    .col-item {
      color: lightgray;
      font-size: 12px;
      margin-bottom: 8px;
    }

    .inner-sec {
      display: flex;
      flex-direction: column;
      row-gap: 40px;
      width: 100%;
      align-items: center;

      .inner-block {
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 24px;

        .blue-txt {
          cursor: pointer;
          font-size: 15px;
          font-weight: 400;
          color: #00527F;
        }

        .gray-txt {
          cursor: pointer;
          font-size: 14px;
          font-weight: 400;
          color: #7E7E81;
        }

      }
      .copyright-text {
        font-size: 12px;
        font-weight: 400;
        color: #AFAFAF;
      }
    }
    .bottom-sec {
      width: 100%;
      display: flex;
      justify-content: center;
      font-size: 12px;
      font-weight: 400;
      color: #CFCFCF;

      .creator-name {
        color: inherit;
        text-decoration: none;
      }
    }
  }
}

.marketing-header {
  position: relative;
  height: 80px;
  background: #131F6B;
  justify-content: center !important;
  z-index: 9999999;
  .event-nav {
    ul {
      list-style-type: none;
      li {
        margin-left: 40px;
        &.is-big-nav {
          a {
            font-size: 25px;
            color: #FFFFFF;
            font-weight: 400;
          }
        }
      }
      a, .drop-link {
        color: #B5BEF6;
        font-size: 14px;
        font-weight: 200;
        line-height: 24px;
        &:hover {
          text-decoration: none;
        }
        &.active {
          color: #ffffff;
          font-weight: 400;
        }
      }
    }
  }
  .avatar-block {
    display: flex;
    align-items: center;
    gap: 15px;
    .name-block {
      color: #B5BEF6;
      align-items: center;
      p {
        margin: unset;
        font-size: 12px;
        line-height: 18px !important;
      }
    }
    .avatar {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      overflow: hidden;
    }
  }
  .float-link {
    font-size: 25px;
    color: #FFFFFF;
    position: absolute;
    top: 17px;
    right: 14px;
    font-weight: 400;
    line-height: 18px;
    &:hover {
      text-decoration: none;
    }
  }
}

.drop-link {
  cursor: pointer;
  display: inline-block;
  position: relative;
  .drop-link-menu {
    margin: 0;
    margin-top: 10px;
    padding: 0;
    position: absolute;
    top: 100%;
    background: #fff;
    border-radius: 5px;
    list-style-type: none;
    padding: 5px 0;
    z-index: 1000000;
    li {
      margin-left: 0 !important;
      a {
        display: block;
        white-space: nowrap !important;
        font-size: 14px;
        color: #222222;
        line-height: 18px;
        width: 100%;
        padding: 4px 10px;
        &:hover {
          background: #F0F0F0;
          color: #008DA8;
        }
      }
    }
  }
  &.open {
    svg {
      transform: rotate(180deg);
    }
  }
}

.spotlight-block {
  position: relative;
  z-index: 9999;
  position: absolute;
  top: -16px;
  right: 80px;
  &.has-no-frame {
    .inner-img {
      max-width: unset;
    }
  }
  &.hasAnimation {
    @include apply-swing-top-center;
  }
  .inner-img {
    position: absolute;
    right: 42px;
    top: 210px;
    z-index: 999999;
    width: 200px;
    height: auto;
  }
  .frame {
    position: relative;
    transform-origin: center;
    z-index: 9999999;
    object-fit: fill;
  }
  .badge-name {
    position: relative;
    margin-top: -74px;
    font-size: 18px;
    font-weight: 700;
    color: #000;
    z-index: 9999999;
    text-align: center;
  }
}