.form-select {
  border-bottom: 2px solid #d3d4d7;
  width: 100%;
  padding-bottom: 5px;
  select {
    width: 100%;
    border: none;
    background: none;
    margin-left: -4px;
  }
  &.non-selected {
    select {
      color: #757575;
    }
  }
}
