@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap');

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

@import '~video-react/styles/scss/video-react';

$font-roboto: 'Roboto', sans-serif !default;
$font-open-sans: 'Roboto', sans-serif !default;
$font-family-base: $font-roboto;
@import "~bootstrap/scss/bootstrap.scss";

@import './mixins';
@import './variables';

$color-blue: #131f6b;
$color-blue-light: #eff4fb;
$color-blue-semi-light: #ccdef7;
$color-tomato: #d95555;
$color-cyan: #00b5ec;
$color-cyan-semi-dark: #03a3d4;
$color-black-transparent: rgba(0, 0, 0, 0.75);

@import 'pages/auth-pages';
@import 'pages/home-page';
@import 'pages/author-view-page';
@import 'pages/course-view-page';
@import 'pages/course-progress';
@import 'pages/subscriptions-page';
@import 'pages/user-profile';
@import 'pages/testing-modal';
@import 'pages/event-view';
@import 'pages/home-dash';
@import 'pages/coming-soon';
@import 'pages/course-details-public';
@import 'pages/email-verification';
@import 'pages/quiz';
@import 'pages/marketing-landing';
@import 'pages/product';
@import 'pages/product-landing';
@import 'pages/service-and-maintenance';
@import 'forms/input-group';
@import 'forms/select';
@import 'common/type';
@import 'common/alerts';
@import 'common/classes';
@import 'common/button';
@import 'common/fields';
@import 'common/tables';
@import 'common/answerViewer';
@import 'common/informativeMessage';
@import 'common/inputs';
@import 'common/quizModal';
@import 'common/quizFlow';
@import 'common/imageTypeQuestion';
@import 'common/animations';
@import 'common/input';
@import 'common/textarea';
@import 'common/select';
@import 'common/footer';
@import 'common/progresses';
@import 'common/hovered-course-details';
@import 'common/zoomSlider';
@import 'common/sendQuestionSection';
@import 'common/gaidAssociationsBlock';
@import "common/viewer";
@import "common/zoomSlider";
@import 'common/confirmAlert';
@import 'common/canvasView';
@import 'layouts/sidebar';
@import 'layouts/bar-chart';
@import 'layouts/common';
@import 'layouts/dropdown';
@import 'layouts/accordion';
@import 'layouts/calendar';
@import 'layouts/dash-header';
@import 'layouts/modal.scss';

body {
  color: #242836;
  font-size: 17px;
  min-width: 1224px;
}

@media (max-width: 1224px) {
  body {
    min-width: auto;
  }
}

main {
  margin-left: 230px;
  min-width: 1000px;
  min-height: 600px;
  z-index: 1;
  position: relative;
}

.home-page {
  min-width: 780px;
  main {
    margin-left: 0;
    min-width: initial;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
  box-shadow: 0 0 0 30px white inset !important;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  font-size: 17px;
}

// ::-webkit-scrollbar {
//   background-color:#fff;
//   width:16px;
// }

// ::-webkit-scrollbar-track {
//   background-color: #fff;
// }

// ::-webkit-scrollbar-thumb {
//   background-color: #a0a5c5;
//   border-radius: 16px;
//   border: 4px solid #fff;
// }

// ::-webkit-scrollbar-button {display:none}

img {
  max-width: 100%;
}

a.link {
  color: #131f6b;
  font-weight: 500;
  text-decoration: none;
  &.light {
    font-weight: 100;
  }
}

.opc-3 {
  opacity: .3;
}

.opc-6 {
  opacity: .6;
}

.pointer {
  cursor: pointer;
}

.not-allowed {
  cursor: not-allowed;
}

.no-pointer-events {
  pointer-events: none;
}

.m-back {
  margin-right: -15px;
  margin-left: -15px;
}

.flex-1 {
  flex: 1;
}

.rotate-180 {
  transform: rotate(180deg);
}

.search-block {
  position: relative;
  input {
    width: 332px;
    height: 38px;
    border: none;
    background: #eff4fb;
    padding: 0 30px 0 10px;
    outline: none;
  }
  svg {
    position: absolute;
    right: 10px;
    top: 12px;
  }
}

.checkbox-item {
  cursor: pointer;
  height: 38px;
  background: #eff4fb;
  border-radius: 5px;
  padding: 0 12px;

  label {
    cursor: pointer;
    margin: 0;
    margin-left: 8px;
    font-size: 14px;
    color: #222222;
    font-weight: 500;
  }
}

.progress-bar {
  height: 10px;
  background: #fff;
  width: 120px;
  border-radius: 2px;
  overflow: hidden;
  .thumb {
    height: 100%;
  }
}

.text-desc {
  font-size: 14px;
  color: #222222;
  &:not(.bold) {
    font-weight: 100;
  }
}

.certificate-page {
  background: #f0f0f0;
  canvas {
    margin: 20px auto;
  }
}

.pagination {
  justify-content: center;
  margin-top: 20px;
  width: 100%;
  li {
    margin: 0 8px;
    a {
      background: #eff4fb;
      font-size: 10px;
      text-decoration: none;
      cursor: pointer;
      color: #131f6b;
      width: 20px;
      height: 20px;
      line-height: 20px;
      border-radius: 100%;
      text-align: center;
      display: inline-block;
      font-weight: bold;
      &:focus {
        box-shadow: none;
        outline: none;
      }
    }
    &.active a {
      background: #131f6b;
      color: #fff;
    }
  }
  .previous, .next {
    display: none;
  }
}

.find-key-button {
  position: relative;
  .key-image {
    display: none;
    position: absolute;
    bottom: 100%;
    width: 340px;
    margin-left: -110px;
  }
  &:hover .key-image {
    display: block;
  }
}

.step-check-icon {
  position: absolute;
  width: 15px;
  bottom: 6px;
}

.author-bio-short {
  max-height: 50px;
  overflow: hidden;
  &.active {
    max-height: initial;
    overflow: initial;
  }
}

.about-content {
  p {
    color: #495089;
    font-size: 26px;
  }
}

.default-form {
  font-size: 14px;
  li {
    min-height: 49px;
    background: $color-blue-light;
    border-radius: 5px;
    color: #222222;
    font-weight: bold;
    margin-bottom: 7px;
    padding: 9px 9px 9px 21px;

    &:focus-within {
      background: $color-blue-semi-light;
    }
  }

  span.result {
    font-weight: 300;
    padding: 0 13px;
  }

  .input-group {
    border: none;
    margin: 0 !important;
  }

  .input-group input, select, textarea {
    padding: 0 13px;
    height: 31px;
    border-radius: 5px;
    background: #fff;
    font-size: 14px;
    border: none;

    &::-webkit-input-placeholder {
      color: #ddd
    }
  }

  textarea {
    resize: none;
    outline: none;
    width: 100%;
    height: 150px;
    padding-left: 13px !important;
    padding-top: 7px !important;
  }

  .input-with-button {
    position: relative;

    input {
      padding-right: 80px;
    }

    button {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }
}

.btn-contact-send {
  font-size: 12px;
  padding: 8px 27px;
  color: #131f6b;
}

.contact-info {
  font-size: 14px;
}

.back-button {
  margin-right: 10px;
  svg {
    height: 18px;
  }
}

.mt--20 {
  margin-top: -20px;
}

.mt-100 {
  margin-top: 100px !important;
}

.blue-box {
  position: relative;
  min-height: 45px;
  background: #eff4fb;
  border-radius: 5px;
  padding: 15px;

  p {
    font-weight: bold;
    color: #242836;
    font-size: 16px;
  }

  .info {
    font-size: 16px;
    color: #242836;
    font-weight: 100;
  }

  input, textarea, select {
    height: 36px;
    border: none;
    background: #ffffff;
    font-size: 15px;
    padding: 5px 10px;
    outline: none;
    border-radius: 5px;
  }

  textarea {
    min-height: 120px;
    resize: none;
  }

  .edit-btn {
    position: absolute;
    right: 20px;
  }
}

.l-0 {
  left: 0
}

[alt='cover'] {
  height: 100px;
  object-fit: cover;
  object-position: center;
}

.contact-category {
  padding: 0;
  border: none;
  select {
    margin-left: 0;
  }
}

.viewer-back {
  svg {
    path {
      fill: #fff;
    }
  }
}

.page-footer {
  background: #eff4fb;
}

.certificate-page {
  background: #eff4fb;
}

.certificate-item {
  position: relative;
  width: 250px;
  .hover-item {
    display: none;
    position: absolute;
    top: 13px;
    left: 16px;
    right: 16px;
    bottom: 18px;
    border-radius: 7px;
    color: white;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.34);
    a.down-icon {
      color: #fff;
      cursor: pointer;
      position: absolute;
      right: 20px;
      top: 15px;
    }
  }
  &:hover {
    .hover-item {
      display: flex;
    }
  }
  &.in-course {
    cursor: pointer;
    margin-bottom: 10px;
    background: #eff4fb;
    p {
      margin-top: 10px;
      text-align: center;
      font-size: 17px;
    }
  }
}

.feedback-image {
  width: 118px;
  height: 100%;
  background: #2B2967;
  color: #fff;
  text-align: center;
  line-height: 60px;
  svg {
    vertical-align: initial !important;
    width: inherit !important;
    height: inherit !important;
    padding: 14px !important;
  }
}

.webinars-block {
  .webinar-title {
    text-align: center;
    color: #2B2967;
    text-transform: uppercase;
    font-size: 17px;
    font-weight: bold;
  }
  p {
    color: #2B2967;
    font-size: 13px;
  }
  .web-name {
    font-size: 17px;
    font-weight: 500;
  }
}

.webinars {
  img {
    width: 232px;
    max-height: 250px;
    margin-right: 4px;
  }
}

.banners-block {
  .slick-dots {
    bottom: 15px;
    li button {
      &:before {
        opacity: 0.4;
      }
    }
    .slick-active {
      li button {
        &:before {
          opacity: 1 !important;
        }
      }
    }
  }
  img {
    max-height: 100%;
    object-fit: contain;
  }
}

.banner-links {
  padding-left: 18px;
  padding-right: 40px;
  width: 420px;
  padding-bottom: 8px;
}

.banner-play {
  position: absolute;
  width: 83px;
  height: 83px;
  border-radius: 100%;
  border-width: 1px;
  background: rgba(19, 31, 107, 0.72);
  z-index: 1;
  text-align: center;
  line-height: 83px;
  top: 50%;
  left: 50%;
  margin-top: -41.5px;
  margin-left: -41.5px;
  border: 1px solid #fff;
  svg {
    width: 27px;
    height: 28px;
    margin-left: 4px;
    path {
      fill: #fff;
    }
  }
}

.spec-title {
  font-size: 30px;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #495089;
}

.specialities {
  .speciality-item {
    width: 33%;
    text-align: center;
    &:hover {
      .img-box {
        border: 2px solid #3b88a9;
        box-shadow: 0 0 10px #3b88a9;
      }
    }
    h5 {
      color: #2b2b63;
      font-size: 16px;
      margin: 20px 0;
    }
  }

  p {
    color: #7e7e81;
    font-size: 15px;
    margin: 0;
    font-weight: 100;
  }

  .img-box {
    margin: 0 auto;
    border: 2px solid #afafaf;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    text-align: center;
    line-height: 100px;
  }
}

.blue-see-more {
  display: inline-block;
  text-align: center;
  line-height: 30px;
  width: 102px;
  height: 30px;
  background: #009ACC;
  color: #fff;
  text-decoration: none;
  opacity: 0.9;
  font-size: 13px;
  font-weight: 500;
  &:hover {
    opacity: 1;
    text-decoration: none;
    color: #fff;
  }
}

.home-courses {
  .home-c-title {
    height: 52px;
    padding: 14px 16px;
    background: #131F6B;
    color: #fff;
    font-size: 17px;
    font-weight: lighter;
  }
  a {
    color: #fff;
    font-size: 13px;
    font-weight: bold;
  }
  .courses .course-item {
    background: #eff4fc !important;
    max-width: 230px;
    width: 100%;
    p.name {
      white-space: inherit !important;
    }
  }
}

.banner-block {
  width: 362px;
  background: #131F6B;
  font-size: 17px;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  padding: 2px 2px 2px 20px;
  a {
    display: block;
    width: 71px;
    background: #EFF3FC;
  }
  &.is-ie {
    height: 120px;
  }
  &.is-other {
    max-height: 120px;
    min-height: 65px;
  }
}

.mr-60 {
  margin-right: 60px !important;
}

.tool-separator {
  margin: 0 8px 0 5px;
  width: 2px;
  height: 30px;
  background: #292929;
}

.ReactModal__Overlay {
  z-index: 1000;
}

.expired-screen {
  p {
    margin-bottom: 11px;
    font-size: 17px;
    font-weight: bold;
    &.line-1-text {
      color: #131F6B;
    }
    &.line-2-text {
      color: #242836;
    }
    &.line-3-text {
      font-size: 14px;
      color: #131F6B;
      font-weight: 300;
      margin-bottom: 20px;
    }
  }

  button {
    background: #EFF3FC;
    font-size: 12px;
    color: #131F6B;
  }
}

.course-thumb-info {
  line-height: 11px;
  background: rgba(0,0,0,0.2);
}

.registration-success {

  h1 {
    letter-spacing: 0.4px;
    color: #242836;
    margin-bottom: 11px;
  }

  p {
    font-size: 17px;
    letter-spacing: 0.34px;
    line-height: 24.4px;
    color: #242836;
    font-weight: 100;
    b {
      color: #131F6B;
      font-weight: bold;
    }
  }
  .auth-success-block {
    border-bottom: 4px solid #d3d4d7;
    padding: 0 30px 8px 30px;
    margin-bottom: 0px;
  }

  button {
    color: #131F6B;
    letter-spacing: 0.34px;
    font-size: 17px;
    img {
      margin-right: 10px;
    }
    svg {
      margin-left: 21px !important;
    }
  }

  .suc-btns {
    padding: 0 20px;
  }

  &.is-event {
    margin-top: 20px;
    h1 {
      font-size: 28px;
    }
  }
}

.privacy-content {
  font-size: 0.9rem;
  h4 {
    font-size: 20px;
    b {
      font-weight: 400;
    }
  }
}

.public-viewer {
  position: fixed !important;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  .pb-viewer-area {
    height: calc(100% - 48px);
    .viewport-element {
      height: 100%;
    }
  }
}

.fz-14 {
  font-size: 14px;
}

.mt--6 {
  margin-top: -6px;
}


.entity-block {
  border-radius: 5px;
  border: 1px solid #131F6B;
  background: #EFF4FB;
  padding: 22px;
  margin-bottom: 20px;
  img {
    width: 66px;
    height: 66px;
    margin-right: 40px;
    background: #fff;
  }
  p {
    min-width: 120px;
    font-size: 13px;
    color: #131F6B;
    margin: 0;
    font-weight: bold;
  }
  h4 {
    color: #131F6B;
    margin: 0;
    font-weight: bold;
    &.name {
      font-size: 24px;
    }
    &.address {
      font-size: 16px;
    }
  }
}

.btn-line-blue {
  color: #131F6B;
  font-size: 17px;
  svg path {
    fill: #131F6B;
  }
}

.subs-plan-area {
  background: #EFF4FB;
  border-radius: 5px;
  min-height: 82px;
  padding: 9px;
  font-size: 11px;
  margin: 6px 0 12px 0;
  max-height: 155px;
  overflow: scroll;
  .plan-box {
    border-radius: 5px;
    background: #FFFFFF;
    padding: 6px 12px;
    margin-bottom: 5px;
  }
  .plan-name {
    color: #242836;
  }
  button {
    text-align: right;
    min-width: 90px;
    margin-left: 50px;
    font-size: 11px;
    padding: 0;
    font-weight: bold;
    text-transform: uppercase;
  }

  .input-group {
    border: none;
    background: #fff;
    padding: 0;
    input {
      height: 30px;
      border-radius: 5px;
      padding: 5px 9px;
      font-size: 13px;
    }
  }

  &.no-min-h {
    min-height: initial;
  }
}

.col-switch-btn {
  background: #EFF4FB;
  border-radius: 5px;
  width: 38px;
  height: 38px;
}

.top-fixed-menu {
  z-index: 1000;
  position: absolute;
  top: 21px;
  right: 17px;
  &.on-home {
    top: 7px;
    right: 39px;
  }
}

.course-links.has-m {
  margin-right: 40px;
}

.slider-video-block {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
}

.text-two-line {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-three-line {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tags-view-block {
  margin: 10px 0;
  border-radius: 3px;
  background: #EFF4FB;
  padding: 8px 17px 15px;

  h1 {
    color: #242836;
    font-size: 17px;
    line-height: 20px;
    margin-bottom: 14px;
  }

  .tag-wrap {
    margin-left: -5px;
    margin-right: -5px;
  }

  .tag-item {
    font-size: 13px;
    color: #131F6B;
    background: #FFFFFF;
    border-radius: 5px;
    padding: 6px 9px;
    margin: 5px;
  }
}

.cookie-popup {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 16px 30px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px -6px 13px #00000029;
  font-size: 14px;
  color: #242836;
  z-index: 1000;

  .cookie-btns {
    .cookie-settings {
      font-size: 14px;
      color: #242836;
      text-decoration: underline;
      font-weight: normal;
    }
    .btn-success {
      font-size: 12px;
      height: 35px;
      border-radius: 5px;
      background: #5BA83A;
      color: #FFFFFF;
      font-weight: normal;
      padding-left: 25px;
      padding-right: 25px;
      margin-left: 30px;
    }
  }
}

.cookie-settings-modal {

  .cookie-content {
    padding: 15px 0 13px 0;
    p {
      font-size: 14px;
      color: #242836;
      margin-bottom: 20px;
      line-height: 22px;
    }
  }

  .modal-footer {
    border-top: 1px solid #d3d4d7;
    padding: 0;
    padding-top: 15px;
    button {
      font-size: 14px;
      font-weight: normal;
      background: #5BA83A;
      border-radius: 5px;
      padding: 7px 12px;
    }
  }
}

.accordion-cookie {
  .accordion-item {
    margin-bottom: 6px;
    .item-header {
      padding: 14px 15px 14px 36px;
      font-size: 14px;
      line-height: 13px;
      &:before, &:after {
        right: initial;
        left: 13px;
        top: 13px;
      }
      &:before {
        top: 10px;
      }
      &:after {
        top: 12px;
      }
      &.active {
        &::before {
          top: 19px;
        }
        &::after {
          top: 17px;
        }
      }
    }
    .item-content {
      background: #fff;
      padding: 13px 24px;
      font-size: 13px;
      line-height: 22px;
      color: #242836;
    }
  }
}

.custom-switch {
  position: relative;
  .custom-switch-label {
    margin-right: 21px;
    font-size: 12px;
    color: #242836;
  }
}

.multi-select-pre__control {
  border-color: #c0c3c7 !important ;
}

.multi-select {
  .multi-select-pre__multi-value {
    background: #EFF4FB;
    border-radius: 5px;
    .multi-select-pre__multi-value__label {
      color:#131F6B;
      font-weight: normal;
    }
    .multi-select-pre__multi-value__remove {
      svg {
        width: 10px;
        height: 10px;
        path: {
          fill: #131F6B;
        }
      }
      &:hover {
        background: #e1ecf9;
        color: #131F6B;
      }
    }
  }
}

.multi-select-pre__placeholder {
  font-weight: normal;
  color: #dddddd !important;
}

.answer-viewer-steps {
  &.scrollable {
    display: flex;
    position:  relative;
    width: 100%;
    overflow-x: scroll;
    gap: 10px;
    justify-content: center;

    .scollable_image_list{
      position:  relative;
      display: flex;
      overflow-x: scroll;
      gap: 10px;
      padding: unset;

      div {
        min-height: 180px;
        min-width: 300px;
      }
    }
  }

  .image-list {
    > div {
      min-height: 180px;
    }
  }
}

.text-annotations {
  color: #242836;
  // font-size: 9px;
}

.enroll-text {
  font-weight: 400 !important;
  font-size: 11px !important;
  color: #777777 !important;
  margin-bottom: 0 !important;
  text-align: center;
  max-width: 180px;
  align-self: center;
}

.promo-page {
  .content {
    background: #168ad9;
  }
  .header, .footer {
    height: 80px;
    background-size: cover;
    p {
      font-size: 28px;
      color: #FCFCFC;
      margin: 0;
    }
  }
  .header {
    background-image: url('../assets/promo/topbg.png');
  }
  .footer {
    background-image: url('../assets/promo/footerbg.png');
  }
  button {
    color: #FFFFFF;
    height: 45px;
    width: 120px;
    border-radius: 5px;
    border: 1px solid #FCFCFC;
    &:hover {
      color: #FFFFFF;
    }
  }
}

.top_10 {
  top: 10px;
}

.right_10 {
  right: 10px;
}

.no_bg {
  background: transparent !important;
}

.hide_scrollbar {
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.fsize_18 {
  font-size: 18px;
}

.no-access {
  h2 {
    font-size: 24px;
    color: #E88989;
    margin-bottom: 16px;
    svg {
      margin-right: 12px;
    }
  }
}

.row-reverse {
  flex-direction: row-reverse;
}
.bg-lightgray {
  background: #F3F3F3;
}

.gap-4 {
  gap: 4px;
}

.custom-vertical-scrollbar-block {
  position: relative;
  &::-webkit-scrollbar {
    position: absolute;
    width: 8px;
    margin-top: 16px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
    width: 8px;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #131F6B;
    border-radius: 5px;
    width: 8px;
  }
}

.mw-200 {
  max-width: 200px;
}

.btn-disabled-gray {
  color: #FBFBFB;
  background-color: #B1B1B1;
  pointer-events: none;
}

.img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.max-row-5 {
  display: block;
  overflow: hidden;
  line-height: 1.4em;
  max-height: 7em;
  text-overflow: ellipsis;
}

.max-row-4 {
  display: block;
  overflow: hidden;
  line-height: 1em;
  max-height: 4em;
  text-overflow: ellipsis;
}

.text-align-left {
  text-align: left !important;
}

.bold-text-blue-md {
  font-size: 14px;
  font-weight: 800;
  color: $text-main-color;
}

.snackbar-message, #client-snackbar {
  white-space: pre-line;
}

.react-confirm-alert-overlay {
  z-index: 9999999;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.global_disabled {
  opacity: 0.6;
  pointer-events: none;
}

.z-index-5 {
  z-index: 99999 !important;
}