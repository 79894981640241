.hide {
  display: none;
}

.page-title {
  padding: 33px 21px 20px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1)
}

.list-default {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    border-radius: 5px;
    background: #eff4fb;
    padding: 18px;

    &:not(:last-child) {
      margin-bottom: 6px;
    }
  }
}

.plan-progress {
  width: 100%;
  display: block;
  height: 10px;
  border-radius: 2px;
  background: #fff;
  overflow: hidden;

  .progress-completed {
    height: 100%;
    display: block;
    background: #131f6b;
    border-radius: 2px;
  }

  .progress-line {
    height: 100%;
    display: block;
    border-radius: 2px;
    &.progress {
      background: #B5BEF6;
    }
    &.completed {
      background: #131F6B;
    }
    &.correct {
      background: #00C30A;
    }
    &.incorrect {
      background: #D95555;
    }
  }

  &.progress-incomplete .progress-completed {
    background: #D95555;
  }

  &.rounded {
    &, .progress-completed {
      border-radius: 10px;
    }
  }

  &.advanced {
    .progress-line.incorrect {
      margin-left: -3px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.bg-semi-light {
  background-color: #E5E5E5
}


.icons-list path {
  fill: #131f6b
}

.border-primary {
  border-color: #131f6b !important;
}

.app-tooltip {
  position: relative;

  .tooltip-content {
    width: max-content;
    max-width: 200px;
    padding: 10px 15px 8px;
    border-radius: 4px;
    font-size: 12px;
    line-height: 16px;
    text-align: left;
    background: #eff4fb;
    color: #131f6b;
    font-weight: normal;
    position: absolute;
    z-index: 999999;
    transition: 100ms;
    cursor: default;

    &::after {
      content: '';
      width: 12px;
      height: 12px;
      position: absolute;
      background: inherit;
      z-index: -1;
    }
  }

  &__dark {
    .tooltip-content {
      background: #131f6b;
      color: #eff4fb
    }
  }

  &__left,
  &__right {
    .tooltip-content {
      top: 50%;
      transform: translateY(-50%) scale(0);
      &::after {
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
      }
    }
  }
  &__top,
  &__bottom {
    .tooltip-content {
      left: 50%;
      transform: translateX(-50%) scale(0);
      &::after {
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
      }
    }
  }

  &__left .tooltip-content {
    transform-origin: top right;
    right: 100%;
    margin-right: 10px;
    &::after {
      right: -5px;
    }
  }
  &__right .tooltip-content {
    transform-origin: top left;
    left: 100%;
    margin-left: 10px;
    &::after {
      left: -5px;
    }
  }
  &__top .tooltip-content {
    transform-origin: bottom center;
    bottom: 100%;
    margin-bottom: 10px;
    &::after {
      bottom: -5px;
    }
  }
  &__bottom .tooltip-content {
    transform-origin: top center;
    top: 100%;
    margin-top: 10px;
    &::after {
      top: -5px;
    }
  }

  &__pinned-top,
  &__pinned-up {
    .tooltip-content {
      transform-origin: left 10px;
      transform: scale(0) translateY(0);
      &::after {
        transform: rotate(45deg);
      }
    }
  }

  &__pinned-top .tooltip-content {
    top: 0;
    &::after {
      top: 5px;
    }
  }
  &__pinned-up .tooltip-content {
    top: -5px;
    &::after {
      top: 12px;
    }
  }

  &__left:hover, &__left.hover,
  &__right:hover, &__right.hover {
    .tooltip-content {
      transform: scale(1) translateY(-50%);
    }
  }

  &__top:hover, &__top.hover,
  &__bottom:hover, &__bottom.hover {
    .tooltip-content {
      transform: scale(1) translateX(-50%);
    }
  }

  &__pinned-top:hover, &__pinned-top.hover,
  &__pinned-up:hover, &__pinned-up.hover {
    .tooltip-content {
      transform: scale(1) translateY(0);
    }
  }

  &__pinned-left {
    .tooltip-content {
      transform-origin: top left;
      transform: scale(0) translateX(0);
      left: 0;
    }
    &:hover, &.hover {
      .tooltip-content {
        transform: scale(1) translateX(0);
        left: 0;
        &::after {
          transform: rotate(45deg) translate(0);
          left: 8px;
        }
      }
    }
  }
}

.rating {
  unicode-bidi: bidi-override;
  direction: rtl;
  position: relative;
  font-size: 28px;

  span {
    &:not(:first-child) {
      margin-right: 5px;
    }
  }

  &__completed:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
  }
}
.rating > span:hover:before,
.rating > span.active:before,
.rating > span:hover ~ span:before,
.rating > span.active ~ span:before {
  content: "\2605";
  position: absolute;
  color: rgba(19, 31, 106, 0.19);
  text-shadow: 0 0 1px;
}

.rating > span:not(.active):hover:before,
.rating > span:not(.active):hover ~ span:before{
  color: rgba(19, 31, 106, 0.19);
  text-shadow: 0 0 1px;
}

.rating > span.active:before,
.rating > span.active ~ span:before {
  color: #131f6b;
  text-shadow: 0 0 1px;
}

.rating > span:hover:before {
  content: "\2605";
  position: absolute;
  color: rgba(19, 31, 106, 0.19);
  text-shadow: 0 0 1px;
}

.progress-circles {
  font-size: 15px;
  color: #7F8086;
  span {
    margin-right: 8px;
    display: block;
    width: 15px;
    height: 15px;
    border: 1px solid #707070;
    border-radius: 100%;
    &.progress {
      background: #B5BEF6;
    }
    &.completed {
      background: #131F6B;
    }
    &.correct {
      background: #00C30A;
    }
    &.incorrect {
      background: #D95555;
    }
  }
}

.color-blue {
  color: #131F6B;
}

.bg-light-blue {
  background: #EFF4FB !important;
}
