.home-slide  {
  .slick-dots li {
    margin: 0 !important;
    button:before {
      color: #00499b !important;
      font-size: 8px !important;;
    }
  }

  .slick-list {
    padding-top: 47px !important;
    margin-top: -47px !important;
  }

  .slick-slider {
    .slick-prev:before,
    .slick-next:before {
      color: #000;
    }
  }
}

.text-date {
  font-size: 10px;
  color: #a0a0a0;
}

.color-tomato {
  color: #d95555;
}

.text-size-15 {
  font-size: 15px;
}

.title-line {
  span {
    font-size: 14px;
    color: #242836;
    font-weight: bold;
  }
  svg path{
    fill: #131f6b;
  }
  &:after {
    content: '';
    width: 100%;
    height: 2px;
    background: #eff4fb;
  }
}

.time-line-info {
  padding-left: 22px;
  .title {
    position: relative;
    font-size: 14px;
    color: #222222;
    font-weight: bold;
    &:before {
      content: '';
      position: absolute;
      left: -21px;
      top: 4.5px;
      display: block;
      width: 12px;
      height: 12px;
      border-radius: 100%;
      background: #d1d1d1;
    }
    &.active:before {
      background: #131f6b;
    }
  }

  .description {
    position: relative;
    font-size: 11px;
    color: #777777;
    &:before {
      content: '';
      position: absolute;
      left: -15px;
      top: 0px;
      height: 100%;
      border-left: 2px dashed #b8bbd2;
    }
  }
}

.btn-line {
  color: #242836;
  font-weight: 500;
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.error-text {
  color: #ff6060;
  font-size: 12px;
}
