.zoom-slider-label {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 1px solid #D5D5D5;
  border-radius: 8px;
  color: #242836;
  font-size: 14px;
  font-weight: 400;
}

.zoom-actions {
  display: flex;
  align-items: center;
  gap: 12px;
}

.zoom-slider {
  width: 200px;
  margin-right: 1rem;
}