/* CanvasView.css */

.viewport-element {
  position: relative;
  width: 100%;
  height: 100%;
}

.canvas-overlay {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  display: flex;
  justify-content: center;
}

.overlay-close-container {
  position: absolute;
  bottom: 10px;
  z-index: 1001;
  background-color: rgba(0, 0, 0, 0.8);;
  display: flex;
  justify-content: center;
  width: 100%;
  bottom: -44px;
  .overlay-close-button {
    left: 50%;
    background-color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
  }
}


.overlay-close-button:hover {
  background-color: #f0f0f0;
}

.overlay-canvas {
  position: relative;
  width: 80%;
  height: 80%;
}
