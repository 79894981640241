.modal-custom {
  width: 50%;
  height: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px 45px;
  background-color: $color-black-transparent;
  color: #fff;
  display: flex;
  flex-direction: column;

  &.modal {
    &__fullscreen {
      width: 100%;
      height: 100%;
    }
    &__lg {
      width: 95%;
      height: 700px;
      max-height: 95%;
    }
    &__md {
      width: 70%;
      height: 400px;
      max-height: 55%;
    }
    &__sm {
      width: 500px;
      height: 400px;
      max-height: 45%;
    }
    &__xs {
      width: 300px;
      height: 200px;
      max-height: 35%;
    }
    &__auto-width {
      width: auto;
      max-width: 100%;
    }
    &__auto-height {
      height: auto;
      max-height: 90%;
    }
    &__fluid {
      padding: 18px;
    }
  }

  &.rounded-corners {
    border-radius: 15px;
  }

  &.modal-light-blue {
    &__transparent {
      background-color: rgba(47, 129, 154, 0.66);
      color: #fff;
    }
  }

  .inner-content {
    flex: 1;
    overflow: auto;
  }
}

.modal-questionary,
.modal-explanation,
.modal-heatmap {
  height: unset !important;
  max-height: none;
  top: -48px;
  left: 0;
  bottom: 0;
  right: 0;
  background: #181818;
  transform: none;

  .questionary-content {
    @extend .d-flex;
    @extend .flex-column
  }

  .question-parent,
  .question-group{
    background-color: #E5E5E5;
    border-radius: 8px;
    padding: 8px 9px;

    &:not(:last-child) {
      margin-bottom: 9px;
    }
    &.a-correct {
      label {
        color: #00C30A;
        font-weight: bold;
      }
    }
    &.a-incorrect {
      label {
        color: #EB4D4D !important;
        font-weight: bold;
      }
    }
  }

  .question-group {
    margin-top: -9px;
    padding: 0;
    position: relative;

    &::after {
      content: '';
      background-color: #E5E5E5;
      position: absolute;
      top: -7px;
      left: 0;
      right: 0;
      height: 14px;
    }

    &::before {
      content: '';
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 1px;
      background-color: #181818;
    }
  }

  .question-parent.active + .question-group {
    display: block;
  }

  .question-child:not(:last-child) {
    margin-bottom: 9px;
  }

  .sub-question-group {
    padding: 9px 9px 9px 41px;

    &:not(:last-child) {
      border-bottom: 1px solid #181818;
    }
  }

  .sub-question-group {
    .question-child {
      &.a-correct {
        label {
          color: #00C30A;
          font-weight: bold;
        }
      }
      &.a-incorrect {
        label {
          color: #EB4D4D !important;
          font-weight: bold;
        }
      }
    }
    &__checkbox {
      @extend .d-flex;
      @extend .flex-wrap;
      @extend .align-items-start;
      @extend .justify-content-between;
      .question-child {
        width: 48%;
        width: calc(50% - 9px);
        padding: 8px 11px;
        background: rgba(171, 171, 171, 0.6);
        border-radius: 10px;
      }

      .input-checkbox {
        &.active {
          background-color: rgba(107, 204, 113, 0.6);
        }
        &.a-incorrect-check {
          background-color: #e98a8a;
        }
        &.a-correct-check {
          background-color: rgba(107, 204, 113, 0.6);
          label {
            font-weight: 900;
            color: #fff;
          }
        }
      }
    }
  }
}

.modal-explanation {
  color: #181818;
  font-family: $font-roboto;
  h5 {
    color: #fff;
    font-size: 20px;
    margin-bottom: 9px;
  }
  .description-area {
    width: 480px;
    min-width: 480px;
    font-size: 16px;
    font-weight: 100;
    padding-bottom: 1rem;
    b {
      font-weight: 500;
      color: #131F6B;
    }

    ul {
      padding-left: 29px;
      font-size: 14px;
    }

    li {
      list-style: none;
      position: relative;

      &::after {
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 4px;
        background-color: #131F6B;
        position: absolute;
        top: 8px;
        left: -29px;
      }
    }
  }
  .description-container {
    border-radius: 10px;
    padding: 15px 25px 15px 19px;
    max-height: calc(100vh - 6rem - 220px);
    overflow: auto;
  }
  .step-description {
    height: 180px;
    > div {
      white-space: pre-wrap;
    }
  }
  .toggle-button {
    color: #131F6B;
    cursor: pointer
  }
  .image-list {
    margin-top: 4px;
    padding: 0 4px;
    div {
      text-align: center;
      background: #000;
      &:nth-child(3n+1) {
        margin-right: 4px !important;
      }
      &:nth-child(3n+3) {
        margin-left: 4px !important;
      }
      img {
        // width: 230px;
        max-height: 170px;
      };
      &.active {
        border: 2px solid #00919c;
      }
    }
  }
}

.modal-heatmap {
  padding: 0 !important;
  h5 {
    font-size: 15px;
    font-weight: normal;
  }
  h4 {
    margin-top: 20px;
    font-size: 24px;
    color: #EDD500;
  }
  .heatmap-content {
    padding: 10px;
    background: #000;
  }
  .image-area {
    position: relative;
    width: 500px;
    margin: auto;
    .circle {
      position: absolute;
      width: 42px;
      height: 42px;
      margin-left: -21px;
      margin-top: -21px;
      border-radius: 100%;
      border: 3px solid #EDD500;
    }
    &.has-cursor {
      cursor: url('../../assets/stepsnew/heatmapcursor.svg') 21 21, auto;
    }
  }
}

.modal-box {
  background: #fff;
  width: 625px;
  height: 460px;
  padding: 20px 30px;
  box-shadow: 0px 4px 59.8px 5.2px rgba(0, 12, 85, 0.14);

  .header {
    padding-bottom: 15px;
    border-bottom: 1px solid #d3d4d7;
    color: #131F6B;
    font-size: 18px;
    font-weight: 500;
  }

  p {
    color: #131F6B;
    font-size: 15px;
    margin-bottom: 1px;
  }

  .content {
    font-size: 15px;

    textarea {
      border-radius: 5px;
      resize: none;
      height: 120px;
      border: none;
      background: #eff4fb;
    }

    .warning-info {
      font-size: 14px;
      color: #242836;
      font-weight: 100;
    }
  }

  .footer {
    height: 40px;
    border-top: 2px solid #d3d4d7;
  }
}

.custom-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
