.btn {
  font-weight: 500;
  // padding-left: 22px;
  // padding-right: 22px;
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.btn-redesigned {
  font-size: 12px;
  font-weight: 400;
}

.btn-blue {
  background: #eff4fb;
  color: #242836;
}

.btn-primary {
  background-color: #131f6b;
  color: #fff;
  border: none;

  &:hover, &:active {
    background-color: #0d154c !important;
    color: #fff !important;
  }
  &:disabled {
    background-color: #131f6b;
  }

  &:focus, &.focus {
    background-color: #0d154c !important;
    box-shadow: none;
  }
}

.btn-blue-dark {
  background: #131f6b;
  color: #fff;

  &:hover {
    color: #fff;
  }
}

.btn-blue-dark-redesigned {
  background: #131f6b;
  color: #FBFBFB;
}

.btn-blue-light {
  background: $color-cyan;
  color: #fff;

  &:hover {
    color: #fff;
    background: $color-cyan-semi-dark;
  }
}

.btn-rounded {
  border-radius: 50px;
}

.btn-outlined-white {
  border: 2px solid #fff;
}

.btn-white {
  background: #fff;
  color: #242836;
}

.btt-transparent {
  background: transparent;
  width: fit-content;
  height: fit-content;
}

.btn-width-300 {
  min-width: 300px
}

.btn-outline-blue {
  border: 1px solid #131F6B;
  font-size: 12px;
  color: #131F6B;
  font-weight: 500;
}

.btn-size-regular {
  font-size: 16px;
  font-weight: 700;
  padding: 8px 24px;
  border-radius: 5px;
  max-height: 36px;
  line-height: 18px;
}

.btn-purple-filled {
  background-color: #AF3DB2;
  border-radius: 8px;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 700;
}