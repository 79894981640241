.quiz-flow-container {
  display: flex;
  flex-direction: column;
  background-color: #EFF4FB;
  overflow-y: auto;
  max-height: 460px;
  padding: 16px 16px 8px 16px;
  border-radius: 4px;
  flex: 1;

  .head-part {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  .leave-label {
    width: 84px;
    background-color: white;
    padding: 6px 11px;
    border-radius: 4px;
    color: #131F6B;
    font-size: 12px;
    font-weight: 400;
    white-space: nowrap;
    cursor: pointer;

    &:hover {
      background-color: #131F6B;
      color: white;
    }
  }

  .flow-part-container {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 2px 0;
  }

  .title {
    color: #222222;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 8px;
  }

  .flow-line-block {
    width: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .circle {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: transparent;
      border: 4px solid;
      border-color: #fff;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      &.breakpoint {
        width: 30px;
        height: 30px;
      }
      &.regular {
        border-color: #131F6B;
      }
      &.Success {
        border-color: #2DA41A;
      }
      &.Failure {
        border-color: #FA5252;
      }
      &.NoAnswer {
        border-color: #fff;
      }

      .current-step {
        color: #131F6B;
        font-size: 12px;
        font-weight: 400;
      }
      .inner-circle-regular {
        width: 14px;
        height: 14px;
        background-color: #131F6B;
        border-radius: 50%;
      }
    }

    .circle-labels {
      position: absolute;
      display: flex;
      left: 38px;
      top: -10px;
      gap: 8px;

      &.breakpoint {
        top: -5px;
        left: 45px;
      }

      .label-start, .label-time {
        display: block;
        background-color: white;
        padding: 6px 8px;
        border-radius: 4px;
        color: #131F6B;
        font-size: 12px;
        font-weight: 400;
        white-space: nowrap;

        &.active {
          background-color: #131F6B;
          color: white;
        }
      }
      .label-start {
        border: 1px solid #131F6B
      }
    }

    .line {
      height: 20px;
      width: 2px;
      background-color: white;
      &.passed {
        background-color: #131F6B;
      }
    }
  }
}

