.testing-modal {
  width: 600px;
  position: static;
  margin: 40px auto 0;
  min-height: 400px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 0px 25px 1px;
  border-radius: 4px;
  height: calc(100% - 80px);
  max-height: 650px;

  p {
    line-height: 21px;
    font-size: 16px;
  }

  .modal-link:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  .modal-continue {
    border: none;
    color: #131f6b;

    &:disabled {
      opacity: 0.35;
      color: #333
    }

    &:not(:disabled):hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .box {
    padding: 15px;
    &.white {
      background: #fff;
    }
  }

  .modal-header {
    padding: 27px 40px;
  }
  .modal-body {
    padding: 0 22px 22px;
    background: #eff4fb6b;
    position: relative;
    max-height: calc(100% - 138px);
    overflow: scroll;
  }

  .modal-footer {
    padding: 22px;
    position: relative;
  }

  .spinner-holder {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.5);
  }

  .connection-progress {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    height: 8px;
    border-radius: 8px;
    overflow: hidden;
    margin: 0;

    .progress-completed {
      border-radius: 8px;
      height: 100%;
      background: #242836;
      transition: all 600ms ease-out;
    }
  }
}

.button-times {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 3px;
  right: 4px;
  border: none;

  &::before,
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background-color: #242836;
    transform-origin: center;
    position: absolute;
    top: 50%;
    left: 50%;
  }

  &::before {
    transform: translateX(-50%) rotate(45deg)
  }

  &::after {
    transform: translateX(-50%) rotate(-45deg)
  }

  &.dark {
    background: transparent;
    &::before,
    &::after {
      background-color: #fff;
    }
  }
}

.modal-default {
  padding: 25px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 0px 25px 1px;
}

.subscription-modal {
  width: 350px;
}

.viewer-modal {
  width: calc(90% - 230px);
  height: 90%;
  color: #a9a9a9;
  background: #191818;
  margin-left: 115px;
  z-index: 10000;
}

header[class^="MuiPaper"] {
  background-color: transparent !important;
  button {
    outline: none !important;
  }
}

span[class^="MuiPrivateTabIndicator"] {
  background-color: #00b5ee !important;
}

.help-description {
  img {
    width: 30px;
  }
  button {
    border-radius: 3px;
    border: 2px solid #00b5ee;
    color: #fff;
    background-color: transparent;
    line-height: 20px;
    font-weight: bold;

    &:not(:last-child) {
      margin-right: 25px;
      position: relative;

      &::after {
        content: "+";
        display: inline-block;
        margin: 0 10px;
        color: #fff;
        position: absolute;
        left: 100%;
        top: 50%;
        transform: translateY(-50%);
      }
      &.btn-slash::after {
        content: "/";
        margin: 0 10px 0 12px;
      }
    }
  }
}
