.descriptions-block {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-left: 10px;
  font-size: 12px;

  .drag-descriprion {
    font-weight: 400;
    color: #777777;
  }
  .img-description {
    height: 20px;
    font-weight: 700;
    color: #131F6B;
  }
}

.dnd_block_container {
  border-radius: 4px;
  padding-left: 20px;
  display: flex;
  column-gap: 20px;
  height: 236px;
  padding: 10px;
  .title {
    color: #000;
  }
  .steps_part_block {
    position: relative;
    display: flex;
    column-gap: 16px;
    align-items: center;
    height: 100%;
    min-width: 160px;

  .absolute_overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .left_sticky_div {
    position: sticky;
    left: 0;
    z-index: 99;
  }

  .gradient {
    position: absolute;
    width: 64px;
    height: 130px;
    top: 0;
    z-index: 1;
      &.start {
        left: 80px;
        background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 122.92%);
      }
      &.end {
        right: 0;
        background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 122.92%);
      }
  }

  .draggable_block {
    // position: relative;
    display: flex; 
    flex-direction: column;
    row-gap: 16px; 
    width: 100%; 
    height: 100%;
    align-items: center;
    overflow-y: auto; // Hide vertical scrolling
    overflow-x: hidden;
  }
  }
  .sortable_img_item {
    position: relative;
    border-radius: 6px;
    flex-shrink: 0;
    border: 1px solid #EBEBEB;
    overflow: hidden;
    &.medium {
      width: 134px;
      height: 98px;
    }
    &.small {
      width: 76px;
      height: 56px;
    }
    &.active {
    }
    &.dragging {
      border-color: #131F6B;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .item_remove_icon {
      position: absolute;
      right: 0;
    }
  }
  .selected_item_desc_block {
    display: flex;
    align-items: center;
    gap: 8px;
    height: 40px;
  }
}

.columns_container {
  position: relative;
  display: flex;
  column-gap: 8px;
  width: 100%;
  max-width: 574px;
  overflow-y: hidden;
  overflow-x: auto;
}

.category_column {
  flex: none;
  width: 190px;
  height: 200px;
  border-radius: 6px;
  overflow-y: hidden;
  border: 1px solid #EBEBEB;
  &.wide {
    width: 270px;
  }
  &:hover {
    .not_visible {
      visibility: visible !important;
    }
  }
  &.overed {
    border: 2px dashed #AFAFAF
  }

  .header {
    width: 100%;
    height: 35px;
    background-color: #131F6B;
    display: flex;
    padding: 4px 4px 4px 16px;
    align-items: center;
    gap: 8px;
    
    .title {
      margin-bottom: unset;
      color: #fff;
    }
  }
  .body {
    background-color: #FAFAFA;
    height: calc(100% - 35px);
    width: 100%;
    padding: 15px 12px;
    .column_body_items_block {
       display: flex; 
       column-gap: 7px;
       row-gap: 5px; 
       flex-wrap: wrap;
       max-height: 100%;
       overflow: auto;
    }
  }
}

.empty_col_img_space {
  display: inline-block;
  width: 75px;
  height: 55px;
  border: 2px dashed #AFAFAF;
  border-radius: 6px;
}

.img_desc {
  color: #181818;
}

.disabled_img_desc {
  color: #AFAFAF;
}