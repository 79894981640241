.list_arrow_left {
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: sticky !important;
  left: 0;
  height: 100% !important;
  width: 52px !important;
  min-width: unset !important;
  background: transparent linear-gradient(90deg, #000000 0%, #0000004D 100%) 0% 0% no-repeat padding-box !important;
  border-radius: 0px 5px 5px 0px;
  padding: 0 20px;
  &.disabled {
    opacity: 0.6;
    cursor: auto;
  }
}

.list_arrow_right {
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: sticky !important;
  right: -2px;
  height: 100% !important;
  width: 52px !important;
  min-width: unset !important;
  background: transparent linear-gradient(270deg, #000000 0%, #0000004D 100%) 0% 0% no-repeat padding-box !important;
  border-radius: 5px 0px 0px 5px;
  padding: 0 20px;
  &.disabled {
    opacity: 0.6;
    cursor: auto;
  }
}

.steps_arrows_block{
  background: transparent !important;
}

.birads_step_icon_abs_block {
  > svg {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}