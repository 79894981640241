.coming-soon {
  .header, .footer {
    position: relative;
    height: 146px;
    background: #008DA8;
    text-align: center;
    padding: 27px;
  }

  .content {
    text-align: center;
    color: #008DA8;
    padding-top: 25px;
    padding-bottom: 120px;
    background: url('../../assets/soon/bg1.jpg');
    background-size: cover;
    h1 {
      font-weight: 400;
      font-size: 50px;
      margin-bottom: 20px;
      // line-height: 24px;
    }
    .contact-email {
      font-size: 30px;
      color:#008DA8;
      font-weight: 300;
      a {
        text-decoration: underline;
        color:#008DA8;
      }
    }
  }

  .footer div {
    width: 100%;
    left: 0;
    text-align: center;
    position: absolute;
    bottom: 39px;
  }
}
