.search-input-container {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;
  background-color: #fff;
  max-width: 295px;
  height: 38px;
  flex-shrink: 0;
}

.search-input {
  flex-grow: 1;
  border: none;
  padding: 10px 16px !important;
  font-size: 12px;
  font-weight: 500;
  color: #222222;
  background-color: #fff !important;
  width: 100% !important;
  height: 100% !important;
  &::placeholder {
    color: #95989A;
  }
}

.search-input:focus {
  outline: none;
}

.search-icon {
  position: relative !important;
  top: unset !important;
  right: unset !important;
  cursor: pointer;
}

