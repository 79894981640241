.author-bio-area {
  font-size: 14px;
  color: #777777;
}

.author-view {
  .first-section {
    min-height: 300px;
    max-height: 300px;
  }

  .author-education {
    overflow: auto;
    height: 250px;
    padding-right: 20px;
  }

  .author-section-scroll {
    overflow-y: auto;
    height: 280px;
    min-height: calc(100vh - 480px);
    padding-right: 20px;
  }
}
