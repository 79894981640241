.events {
  .container {
    @media (min-width: 576px) {
      max-width: 750px;
    }
    @media (min-width: 768px) {
      max-width: 950px;
    }
    @media (min-width: 1200px) {
      max-width: 1140px;
    }
  }
}

.event-header {
  position: relative;
  height: 80px;
  background: #131F6B;
  padding: 25px 0 25px 0;
  .event-nav {
    ul {
      list-style-type: none;
      li {
        margin-left: 40px;
        &.is-big-nav {
          a {
            font-size: 25px;
            color: #FFFFFF;
            font-weight: 400;
          }
        }
      }
      a, .drop-link {
        color: #F6F6F6;
        font-size: 20px;
        font-weight: 200;
        line-height: 24px;
        &:hover {
          text-decoration: none;
        }
        &.active {
          font-weight: 400;
        }
      }
    }
  }
  .float-link {
    font-size: 25px;
    color: #FFFFFF;
    position: absolute;
    top: 17px;
    right: 14px;
    font-weight: 400;
    line-height: 18px;
    &:hover {
      text-decoration: none;
    }
  }
}


.event-info {
  background: #F0F0F0;
  padding: 22px 0 20px 0;

  .event-line {
    font-size: 18px;
    line-height: 24px;
    .label-key {
      font-weight: 500;
      color: #008DA8;
    }
    .label-value {
      color: #3D3D3D;
      font-weight: 100;
      margin-left: 5px;
    }
    &.marg-1 {
      margin-bottom: 19px;
    }
    &.marg-2 {
      margin-bottom: 7px;
    }
    &.line-border {
      padding: 18px 0;
      border-top: 1px solid #D1D1D1;
      border-bottom: 1px solid #D1D1D1;
    }
  }
  &.is-webinar {
    background-image: url('../../assets/events/world-wide.svg');
    background-position: 104% 90%;
    background-repeat: no-repeat;
  }
}

.event-about {
  // background: #F0F0F0;
  background: #fff;
  padding: 22px 0;
  h1 {
    margin-bottom: 28px;
  }
  .info {
    margin: 0;
    font-size: 18px;
    color: #3D3D3D;
    span {
      color: #131F6B;
      font-weight: bold;
    }
    p {
      font-weight: 100;
      // display: inline;
      margin-bottom: 15px;
    }
  }
}

.event-speakers {
  position: relative;
  padding-top: 22px;
  background: #F0F0F0;
  // background: #fff;
  h1 {
    line-height: 132px;
    margin-bottom: 28px;
  }

  .event-speakers-list {
    max-height: 572px;
    overflow: hidden;
    position: relative;
    .speaker-block {
      .img-area {
        min-width: 180px;
        max-width: 180px;
        margin-right: 80px;
        margin-bottom: 60px;
        img {
          -webkit-box-shadow: 10px 7px 8px 1px rgba(0,0,0,0.32);
          box-shadow: 10px 7px 8px 1px rgba(0,0,0,0.32);
        }
      }
      p {
        line-height: 24px;
        &.name {
          font-size: 32px;
          font-weight: bold;
          color: #969696;
          margin-bottom: 9px;
          span {
            font-size: 24px;
            font-weight: 40;
          }
        }
        &.profession {
          font-size: 18px;
          font-weight: 100;
          color: #008DA8;
          margin-bottom: 20px;
        }
        &.info {
          font-size: 14px;
          color: #3D3D3D;
        }
      }
    }
  }
  .show-more {
    position: absolute;
    bottom: 0px;
    padding: 0 30px 30px 30px;
    width: 100%;
    text-align: center;
    color: #131F6B;
    font-weight: 500;
    z-index: 5;
    cursor: pointer;
    // background: #f0f0f0eb;
    // -webkit-box-shadow: 0px 27px 7px 13px #f0f0f0e3;
    // box-shadow: 0px -13px 27px 13px #f0f0f0e3;
    // background: #fffffff0;
    // box-shadow: 0px -13px 27px 13px #fffffff0;
    svg {
      margin-right: 5px;
    }
  }
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url('../../assets/events/bg-speakers.png');
    z-index: 2;
    background-repeat: no-repeat;
    background-position: 0 450px;
  }
  &.open {
    &:before {
      background: none;
    }
    .event-speakers-list {
      max-height: initial;
      padding-bottom: 70px;
    }
    .show-more {
      background: none;
      box-shadow: none;
    }
  }
}

.event-program {
  background: #fff;
  padding: 50px 0;
  h1 {
    line-height: 132px;
    margin-bottom: 60px;
  }
  .event-program-list {
    position: relative;
    .scroll-area {
      overflow-x: scroll;
      width: 100%;
    }
    .date-block {
      min-width: 50%;
      .date-title {
        height: 60px;
        background: #008DA8;
        padding: 0 20px 0 80px;
        font-size: 24px;
        color: #F6F6F6;
        font-weight: 500;
        margin-bottom: 36px;
      }
      // &:nth-child(1) {
      //   .date-title {
      //     padding: 0 20px 0 30px;
      //   }
      //   .programs-list {
      //     margin-left: -20px;
      //   }
      // }
      // &:nth-last-child(1) {
      //   .programs-list {
      //     margin-right: 20px;
      //   }
      // }
    }
    .programs-list {
      .program-block {
        padding: 0 20px;
        margin-bottom: 21px;
        .time {
          display: block;
          text-align: center;
          font-size: 28px;
          color: #008DA8;
          font-weight: bold;
          margin-bottom: 2px;
          line-height: 24px;
          .time-a {
            text-transform: uppercase;
            font-size: 20px;
            color: #969696;
            font-weight: 100;
            display: block;
          }
        }
        .desc {
          margin-left: 17px;
          background: #F0F0F0;
          padding: 29px;
          font-size: 14px;
          color: #969696;
          box-shadow: 10px 7px 10px 1px rgba(0, 0, 0, 0.22);
          border-radius: 8px;
        }
        .program-author {
          color: #008DA8;
          margin-bottom: 0;
          margin-top: 6px;
        }
      }
    }
  }
  .arrow-switch {
    position: absolute;
    top: 50%;
    svg {
      cursor: pointer;
      height: 30px;
      path {
        fill: #008DA8;
      }
    }
    &.left {
      left: -40px;
    }
    &.right {
      right: -40px;
    }
  }
}

.event-cme, .event-courses {
  background: #F0F0F0;
  padding: 22px 0 60px 0;
  h1 {
    line-height: 106px;
    margin-bottom: 28px;
  }

  .event-cme-list {
    .cme-block {
      background: #fff;
      width: 330px;
      border-radius: 10px;
      overflow: hidden;
      -webkit-box-shadow: 0px 0px 23px 0px #00000029;
      box-shadow: 0px 0px 23px 0px #00000029;
      .info-area {
        padding: 24px 10px 16px 24px;
      }
      .title {
        font-size: 24px;
        font-weight: 500;
        color: #008DA8;
        margin-bottom: 5px;
        line-height: 24px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .info {
        font-size: 14px;
        color: #3D3D3D;
        margin-bottom: 17px;
        max-height: 44px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      a {
        font-size: 14px;
        color: #008DA8;
        font-weight: bold;
      }
      .img-area {
        position: relative;
        .icon-area {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: 10;
        }
      }
      &.locked {
        .img-area {
          opacity: 0.7;
        }
      }
    }
  }
}

.event-cme {
  background: #FFF;
}

.event-banner {
  background: #F0F0F0;
  .banner-body {
    min-height: 555px;
    max-height: 555px;
    position: relative;
    .top-block {
      background: rgba(#EFF4FB, .7);
      height: 60px;
      padding: 10px 30px;
      ul {
        list-style-type: none;
        li {
          cursor: pointer;
          font-weight: 400;
          margin-right: 25px;
          color: #131F6B;
          font-weight: bold;
          font-size: 22px;
        }
      }
    }
    .center-block {
      padding: 10px 30px;
      width: 310px;
      height: 100%;
      // background: rgba(#131F6B, .9);
    }
    .footer-block {
      background: rgba(#EFF4FB, .7);
      height: 60px;
      color: #131F6B;
      font-size: 24px;
      padding: 10px 30px;
      font-weight: bold;
    }
    .over-block {
      z-index: 1000;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }


  .banner-footer {
    height: 80px;
    background: #131F6B;
    padding: 10px 30px;
    ul {
      list-style-type: none;
      color: #F6F6F6;
      font-size: 15px;
      img {
        margin-right: 10px;
      }
      .title {
        font-size: 18px;
        font-weight: 400;
      }
    }
  }

  .link-block {
    cursor: pointer;
    padding: 10px 15px;
    margin-bottom: 15px;
    font-size: 16px;
    color: #fff;
    width: 250px;
    height: 60px;
    background: rgba(#D1D1D1, 0.3);
    border: 1px solid #fff;
    border-radius: 10px;
    svg {
      height: 35px;
    }
  }

  .buttons-block {
    margin-top: 60px;
    margin-bottom: 31px;
    button {
      width: 120px;
      height: 60px;
      border-radius: 10px;
      font-weight: 400;
    }
  }

  .start-btn {
    width: 120px;
    height: 60px;
    font-size: 28px;
    color: #F2F2F2;
    background: #008DA8;
    box-shadow: 0px 3px 6px #0000008C;
    border: none;
  }
  .access-vip {
    margin-left: 10px;
    width: 120px;
    height: 60px;
    font-size: 20px;
    color: #fff;
    background: rgba(#F0F0F0, 0.3);
    box-shadow: 0px 3px 6px #0000008C;
    border: 1px solid #008DA8;
  }
}


.event-login {
  background: #F0F0F0;
  .event-login-block {
    background: #ffffff;
  }
}

.drop-link {
  cursor: pointer;
  display: inline-block;
  position: relative;
  .drop-link-menu {
    margin: 0;
    margin-top: 10px;
    padding: 0;
    position: absolute;
    top: 100%;
    background: #fff;
    border-radius: 5px;
    list-style-type: none;
    padding: 5px 0;
    z-index: 1000000;
    li {
      margin-left: 0 !important;
      a {
        display: block;
        white-space: nowrap !important;
        font-size: 18px;
        color: #222222;
        line-height: 18px;
        width: 100%;
        padding: 4px 10px;
        &:hover {
          background: #F0F0F0;
          color: #008DA8;
        }
      }
    }
  }
  &.open {
    svg {
      transform: rotate(180deg);
    }
  }
}

.event-footer {
  position: relative;
  background: #fff;
  padding: 72px 0 60px 0;

  h3 {
    font-size: 28px;
    color: #131F6B;
    margin-bottom: 27px;
  }

  p {
    color: #131F6B;
    font-size: 18px;
  }

  a {
    font-size: 16px;
    color: #131F6B;
  }
  img.footer-img {
    position: relative;
    z-index: 2;
    margin-top: 60px;
    margin-bottom: 25px;
  }
  ul {
    list-style-type: none;
  }
  .copyright {
    position: relative;
    z-index: 2;
  }
  &:after {
    z-index: 1;
    content: '';
    height: 253px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #F0F0F0;
  }
}

.about-event {
  padding: 55px;
  font-size: 16px;
  color: #3D3D3D;
  span {
    color: #131F6B;
    font-weight: bold;
  }
}

.large-title {
  position: relative;
  font-size: 80px;
  color: rgba(#D6D6D6, .3);
  font-weight: 800;
  span {
    color: rgba(#008DA8, 0.3);
    font-size: 50px;
    line-height: 66px;
    margin-right: 20px;
  }
}

.cme-text {
  margin-top: 60px;
  font-size: 14px;
  color:#3D3D3D;
  font-weight: 100;
  margin-bottom: 0;
}

.about-sub-title {
  margin: -30px 0 30px 0;
}

.event-register-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #f0f0f0;
  margin-bottom: 20px;
}