.avatar-container,
.user-avatar {
  width: 135px;
  height: 135px;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
  object-position: top;
  position: relative;

  label {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    margin: 0;
    cursor: pointer;
    background: rgba(0, 40, 255, 0.4);
    display: none !important;

    span {
      line-height: 20px;
      color: #fff;
      margin-bottom: 15px;
    }
  }

  &:hover label {
    display: flex !important;
  }

}

.status-box {
  background-color: #eff4fb;
  border-radius: 5px;
}

.list-settings {
  .is-edit {
    padding-top: 12px;
    padding-bottom: 11px;
  }

  .input-group,
  .form-select {
    border: none;
  }

  input, select {
    border-radius: 4px;
    background: #fff !important;
    padding: 4px 15px !important;
    height: unset !important;
  }
}


.password-modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 3;
  background: rgba(226, 226, 226, 0.8);

  .modal-content {
    margin-top: 10vh;
    padding: 30px 50px;
    width: unset;
    background: #fff;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  }

  .modal-header {
    margin: -30px -50px 0;
    padding-bottom: 0;
    border: none;
  }

  .modal-close {
    width: 20px;
    height: 20px;
    position: relative;
    cursor: pointer;

    &:before,
    &:after {
      content: '';
      display: block;
      width: 2px;
      height: 20px;
      transform-origin: center;
      position: absolute;
      top: 50%;
      left: 50%;
      background: #131f6b;
    }

    &:before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &:after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}


.password-input {
  border-bottom: 2px solid #d3d4d7;

  .pass-icon {
    border-right: 2px solid #d3d4d7;
  }
  .input-group {
    border: none !important;
  }
}
