.auth-pages {
  display: flex;
  height: 100vh;
  background-image: url('./../../assets/background.png');
  background-repeat: no-repeat;
  justify-content: center;
  background-position-y: bottom;
  background-size: 100% auto;

  &.event-auth {
    background-image: none;
    background-color: #f0f0f0;
    height: fit-content;
    .form-block {
      background-color: #fff;
      padding: 20px;
    }
  }

  .auth-block {
    width: 480px;
    height: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    flex-direction: column;

    &.large {
      width: 984px;
    }
    &.is-success {
      .logo-block {
        position: absolute;
        top: 60px;
      }
    }
  }

  h3.title {
    font-size: 20px;
    color: #242836;
    font-weight: 500;
  }

  .auth-thanks-block {
    margin-top: 100px;
  }

  .auth-thanks-block {
    .title {
      border-bottom: 2px solid #d3d4d7;
      font-size: 37px;
      font-weight: bold;
      padding-bottom: 26px;
    }
  }

  .auth-success-block {
    margin-top: 50px;
    border-bottom: 2px solid #d3d4d7;
    padding-bottom: 24px;

    .title {
      font-size: 24px;
    }
    .sub-title {
      font-size: 17px;
    }
  }

  .reg-block {
    width: 625px;
    height: 561px;
    box-shadow: 0px 4px 59.8px 5.2px rgba(0, 12, 85, 0.14);
    color: #242836;
    .head-part p {
      font-size: 18px;
      font-weight: bold;
    }
    .head-part, .bottom-part {
      background: #fff;
      min-height: 61px;
    }
    .content-part {
      background: #eff4fb;
      font-size: 14px;
      overflow-y: auto;
      p.stronger {
        font-size: 15px;
        font-weight: bold;
      }
    }
    .bottom-part {
      font-size: 15px;
      color: #242836;
      font-weight: 500;
      a {
        color: #242836;
      }
      button.btn.btn-line {
        color: #131F6B !important;
        font-size: 17px !important;
        &:disabled, &.disabled {
          opacity: 0.3 !important;
        }
      }
      &.reg-form-footer {
        padding: 20px 30px;
        .checkbox-area {
          input {
            margin-top: 4px;
          }
        }
        label {
          font-size: 15px;
        }
      }
    }
  }
}
