input.input-custom[type='radio'],
input.input-custom[type='checkbox'] {
  & + label {
    position: relative;
    display: inline-block;
    cursor: pointer;
    font-size: 14px;
    line-height: 24px;
    height: 24px;
    font-weight: 100;
    color: #181818;
    width: 100%;

    &:before {
      content: '';
      display: block;
      position: absolute;
    }
  }
}

input.input-custom[type='radio'] + label {
  padding-left: 28px;

  &:before {
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 12px solid #939393;
    background: #131F6B;
  }
}

input.input-custom[type='checkbox'] + label {
  padding-left: 28px;

  &:before {
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    line-height: 25px;
    border-radius: 5px;
    font-size: 17px;
    text-align: center;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.4)
  }
}

input.input-custom[type='radio'] {
  opacity: 0;
  position: absolute;
  // display: none;

  &:checked + label {
    font-weight: 900;
    color: #131F6B;
    &:before {
      border: 6px solid #939393;
    }
  }
}

input.input-custom[type='checkbox'] {
  // display: none;
  opacity: 0;
  position: absolute;

  &:checked + label {
    font-weight: 900;
    color: #fff;
    &:before {
      content:'✔';
    }
  }
}
