@import './variables';

@mixin phone {
  @media (max-width: $phone) {
    @content;
  }
}

@mixin ipad {
  @media (min-width: ($phone + 1)) and (max-width: $tablet) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: ($tablet + 1)) {
    @content;
  }
}