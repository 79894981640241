h2 {
  font-size: 25px;
  line-height: 18px;
  font-weight: 100;
}

.font-size-10 {
  font-size: 10px;
}

.font-size-11 {
  font-size: 11px;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-13 {
  font-size: 13px;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-15 {
  font-size: 15px;
}

.weight-100 { font-weight: 100 !important }
.weight-200 { font-weight: 200 !important }
.weight-300 { font-weight: 300 !important }
.weight-400 { font-weight: 400 !important }
.weight-500 { font-weight: 500 !important }
.weight-600 { font-weight: 600 !important }
.weight-700 { font-weight: 700 !important }
.weight-800 { font-weight: 800 !important }
.weight-900 { font-weight: 900 !important }

.color {
  &__success { color: #2edc6c }
  &__warning { color: orange }
  &__danger { color: #e24453 }
}
