.section-header {
  margin-bottom: 20px;
  h2, h3 {
    margin: 0;
    color: #242836;
  }

  h2 {
    font-size: 20px;
  }

  h3 {
    font-size: 17px;
  }

  a {
    text-decoration: none;
    font-size: 12px;
    color: #131f6b;
  }
}

.author-item {
  span {
    margin-top: 10px;
    color: #222222;
    font-size: 14px;
    font-weight: bold;
  }
  p {
    color: #777777;
    font-size: 11px;
  }
  img {
    border-radius: 100%;
    width: 106px;
    height: 106px;
    object-fit: cover;
    object-position: top;
  }

  .image-bordered {
    border: 1px solid rgba(#3c4aa3, .5);
    border-radius: 100%;
    padding: 14px;

    img {
      min-width: 160px;
      min-height: 160px;
      line-height: 160px;
      text-align: center;
    }
  }

  a {
    color: #131f6b;
    font-size: 12px;
    text-decoration: none;
  }
}

.courses {
  .course-item {
    cursor: pointer;
    width: 232px;
    padding: 9px 8px;
    margin-bottom: 10px;
    background: #f6f7f9ba;
    min-height: 253px;

    .image-area {
      height: 126px;
      position: relative;
      border: 1px solid #e4e4e4;
      overflow: hidden;

      -webkit-box-shadow: inset 0px -33px 35px -11px rgba(43,49,107,0.66);
      -moz-box-shadow: inset 0px -33px 35px -11px rgba(43,49,107,0.66);
      box-shadow: inset 0px -33px 35px -11px rgba(43,49,107,0.66);

      .info {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        padding: 6px 10px;

        span {
          color: #ffffff;
          font-size: 11px;
          font-weight: bold;
        }
      }

      .course-icon {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0.8;
      }
    }

    p.name {
      font-size: 13px;
      color: #242836;
      font-weight: bold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    p.description {
      font-size: 11px;
      color: #777777;
      height: 30px;
      overflow: hidden;
    }

    img {
      height: 100%;
      min-width: 100%;
      opacity: 0.5;
      object-fit: cover;
      object-position: top;
    }

    &.active {
      cursor: pointer;
      background: #eff4fb;
      .image-area {
        border-color: #c0c3c7;
        img {
          opacity: 1;
        }
      }
    }
  }
}

.about-info {
  color: #777777;
  font-size: 12px;
}
