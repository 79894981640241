@keyframes swing {
  0%, 100% {
    transform: rotate(-5deg);
  }
  50% {
    transform: rotate(5deg);
  }
}

@keyframes swing-reverse {
  0%, 100% {
    transform: rotate(-3deg);
  }
  50% {
    transform: rotate(3deg);
  }
}

@keyframes focus-border-animation {
  from {
    transform: translate(-50%, -50%) scale(0);
  }
  to {
    transform: translate(-50%, -50%) scale(1);
  }
}

@mixin apply-swing-top-center {
  transform-origin: top center;
  animation: swing 2s ease-in-out infinite;
}

@mixin apply-swing-reverse {
  animation: swing-reverse 2s ease-in-out infinite;
}

@mixin focus-bottom-border-animation {
  animation: focus-border-animation .4s forwards;
}
