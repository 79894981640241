.home-dashboard {
  overflow: hidden;
  width: 1366px;
  padding: 30px 45px;
  .dash-box {
    margin: 15px 0;
    .dash-content {
      overflow: hidden;
      height: 289px;
      // border: 1px solid;
    }
  }
}

.dash-box-info {
  background: url('../../assets/dash/info.png');
  background-size: contain;
  padding: 0 16px;
  img {
    margin-top: 25px;
    margin-bottom: 39px;
  }
  color: #FFF;
  p {
    font-size: 20px;
    line-height: 23px;
  }
}

.dash-box-speciality {
  .spec-box {
    transition: all 250ms ease-in;
    padding-bottom: 10px;
    text-align: center;
    width: 136px;
    height: 136px;
    background: #131F6B;
    font-size: 15px;
    color: #FFFFFF;
    @extend .d-flex;
    @extend .flex-column;
    @extend .align-items-center;
    @extend .justify-content-end;
  }
  img {
    transition: all 250ms ease-in;
    width: 56px;
    &.imgl {
      width: 69px;
    }
  }
  p {
    margin-top: 10px;
    line-height: 17px;
  }
  .spec-box {
    &:hover {
      transition: all 250ms ease-in;
      background: #008AAD;
      img {
        transition: all 250ms ease-in;
        width: 68px;
        &.imgl {
          width: 84px;
        }
      }
    }
    &.skeletal:hover {
      background: #FFB100;
    }
  }
}

.dash-box-events, .dash-box-webinars, .overlay-block, .dash-box-marketing {
  padding: 16px 20px 17px 28px;
  transition: all 250ms ease-in;
  background:linear-gradient(0deg, rgba(#131F6B, 0.4), rgba(#131F6B, 0.4)), url('../../assets/dash/events.png');
  background-size: 100%;
  background-position: center;
  color: #fff;
  &:hover {
    transition: all 250ms ease-in;
    background-size: 115%;

    .title-anim span {
      // transition: all 250ms ease-in;
      // right: 55px;
      // opacity: 1;
    }
  }

  h3 {
    font-size: 18px;
    letter-spacing: 0.36px;
    margin-bottom: 28px;
    line-height: 17px;
    font-weight: bold;
  }

  a {
    line-height: 20px;
    text-align: left;
    color: #fff;
    font-size: 12px;
    letter-spacing: 0.6px;
    font-weight: 500;
    text-shadow: 1px 1px 2px rgba(150, 150, 150, 1);
    svg {
      height: 12px;
      @extend .align-baseline;
      margin-left: 5px;
    }
    &:hover {
      text-decoration: none;
    }
  }

  .title-anim {
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.75px;
    font-weight: 300;
    img, svg {
      margin-left: 10px;
    }
    span {
      text-shadow: 1px 1px 2px rgba(150, 150, 150, 1);
      // transition: all 250ms ease-in;
      // right: -140px;
      margin-top: 1px;
      // position: absolute;
      width: 100%;
      // opacity: 0;
    }
  }
}

.dash-box-webinars {
  background-image:linear-gradient(0deg, rgba(#131F6B, 0.4), rgba(#131F6B, 0.4)), url('../../assets/dash/webinars.png');
}

.dash-box-marketing {
  background-image: linear-gradient(0deg, rgba(#131F6B, 0.4), rgba(#131F6B, 0.4)), url('../../assets/home-marketing-bg.png');
  transition: all 250ms ease-in;
  background-size: 110%;

  .title {
    width: fit-content;
  }
}

.dash-box-events, .dash-box-webinars {
  &:hover {
    .title-anim span {
      // right: 68px !important;
      // opacity: 1;
    }
  }
}

.dash-box-courses {
  position: relative;
  .slick-slide {
    position: relative;
  }
  .overlay-block {
    color: #fff;
    width: 100%;
    height: 289px;

    > div {
      z-index: 1;
    }

    p {
      font-size: 13px;
      margin-bottom: 27px;
      letter-spacing: 0.65px;
      line-height: 20px;
      font-weight: 200;
    }
    &::before {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      bottom: 0;
      background: rgba(#131F6B, .5);
    }
    &.is-second {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 111;
      background: transparent;
      &::before {
        display: none;
      }
    }
  }
  .courses-block {
    &:hover {
      .overlay-block {
        transition: all 250ms ease-in;
        background-size: 110%;
      }
    }
  }
}

.dash-box-transcript {
  .courses {
    .course-item {
      height: 289px;
      width: 100%;
      padding: 0;
      .image-area {
        height: 162px;
        border: 1px solid #E3E3E3;
        img {
          width: 100%;
          max-width: 100%;
          transition: all 250ms ease-in;
        }
      }
      .name {
        font-size: 15px;
        color: #242836;
      }
      .description {
        display: none;
      }
      .course-item-footer {
        padding: 0 15px;
      }
    }
  }
  .slick-slide {
    position: relative;
  }
  .overlay-block {
    background: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 289px;
    color: #fff;
    > div {
      z-index: 2;
    }
    a {
      font-weight: 500;
      color: #fff;
    }
    &:before {
      content: '';
      position: absolute;
      top: 0;
      background: rgba(#131F6B, 0.5);
      height: 48px;
      width: 100%;
      left: 0;
      z-index: 1;
    }
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      background: rgba(#131F6B, 0.5);
      height: 48px;
      width: 100%;
      left: 0;
      z-index: 1;
    }
  }
  &:hover {
    .courses .course-item .image-area img {
      transition: all 250ms ease-in;
      margin-left: -5%;
      width: 110%;
      max-width: 110%;
    }
  }
}

.dash-box-event {
  img {
    max-height: 289px;
  }
  .slider-area {
    .slick-slide.slick-active.slick-current {
      margin-left: -304px;
      img {
        float: right;
      }
    }
  }
  .event-info-block {
    background:rgba(#008AAD, 0.9);
    position: absolute;
    height: 289px;
    width: 304px;
    right: 0;
    padding: 18px 30px 30px;
    color: #fff;
    .ib-title {
      text-transform: uppercase;
      font-size: 18px;
      font-weight: bold;
    }
    h3 {
      font-size: 24px;
      font-weight: 600;
      line-height: 34px;
      letter-spacing: 0.48px;
      margin-bottom: 18px;
    }
    p {
      margin: 0;
      font-size: 15px;
      line-height: 17px;
      letter-spacing: 0.3px;
      font-weight: 200;
    }
  }
}

.dash-box-authors {
  position: relative;
  background: #131F6B;

  .slick-slide {
    height: 100%;
    overflow: hidden;
    position: relative;
  }

  .overlay-block {
    background: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 289px;
    padding-bottom: 15px;
    a {
      font-size: 11px;
      svg {
        height: 10px;
      }
    }
  }

  .authors-block {
    height: 289px;
    color: #fff;
    font-size: 10px;
    letter-spacing: 0.2px;
    line-height: 7px;
    margin: 0;
    text-align: center;
    padding: 49px 30px 44px 30px;
    p {
      margin-bottom: 7px;
      line-height: 13px;
    }
    .img-block {
      width: 60px;
      height: 61px;
      text-align: center;
      margin: 0 auto 7px auto;
      border-radius: 100%;
      border: 1px solid rgba(#3C4AA3, 0.5);
      padding: 8px;
      overflow: hidden;
      transition: all 250ms ease-in;
      img {
        border-radius: 100%;
        min-height: 100%;
        min-width: 100%;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
  &:hover {
    .img-block {
      transition: all 250ms ease-in;
      padding: 2px;
    }
  }
}

.overlay-block {
  background-repeat: no-repeat;
  background-position: top;
}
