.product-signin-left-layout-container {
  position: relative;
  overflow: hidden;
  padding: 0;
  height: 100vh;
  width: 100%;
  .content {
    height: 100%;
    padding: 0 134px 0 80px;
    display: flex;
    font-size: 16px;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: 0;
    color: #fff;
    .left-sec-title {
      font-size: 84px;
      font-weight: 700;
      align-self: center;
      &.welcome {
        align-self: flex-start;
        margin-bottom: 0;
      }
    }
    .welcome-sec {
      width: 100%;
      font-size: 64px;
      font-weight: 300;
      display: flex;
      flex-direction: column;
      .welcome {
        align-self: flex-start;
      }
      .user {
        align-self: flex-end;
      }
    }
    &.with-entity {
      padding: 40px 32px 60px 90px;
      .entity-select-forward {
        align-self: flex-end;
        a {
          color: inherit;
        }
      }
    }
  }
  .background {
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #292B63 10.49%, #292B63 43.5%, #337C99 93.65%), linear-gradient(0deg, #D9D9D9, #D9D9D9);
    .purple-point {
      // pointer-events: none;
      // position: absolute;
      // border-radius: 50%;
      // width: 200px;
      // height: 400px;
      // background-image: radial-gradient(ellipse at center, rgba(128, 0, 128, 0.7) 20%, rgba(128, 0, 128, 0.3) 70%, rgba(128, 0, 128, 0.1) 100%);
      // transition: left 0.1s, top 0.1s;
      // filter: blur(8px);
    }
  }
}

.product-login-block {
    row-gap: 32px;
    padding: 94px 40px 48px 40px;
    height: 100vh;
    overflow-y: auto;
    .form-title-block {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 16px;
    }
    .desc-title {
      color: #777777;
      font-size: 21px;
      font-weight: 400;
    }
    .desc-subtitle {
      color: #777777;
      font-size: 16px;
      font-weight: 400;
      max-width: 243px;
      text-align: center;
    }
    .input-item {
      width: 278px;
      height: 26px;
      margin-bottom: 8px !important;

      &:focus {
        border-bottom: 2px solid #292B63 !important;
      }
    }
    .form-block {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 40px;
      .inputs-block {
        display: flex;
        flex-direction: column;
        row-gap: 32px;
      }
      .forgot-pass-link {
        color: #131F6B;
        font-size: 14px;
        font-weight: 400;
        align-self: flex-end;
      }
      .form-button {
        margin-top: 100px;
        font-size: 12px;
        font-weight: 400;
      }
    }
  .not-have-account-block {
    width: 100%;
    display: flex;
    flex-direction: column;
    .question-row {
      display: flex;
      column-gap: 16px;
      align-items: center;
    }
    .sign-up-forward {
      align-self: center;
      color: #131F6B;
      font-size: 17px;
      font-weight: 600;
    }
    .sign-up-link {
      display: flex;
      justify-content: center;
    }
    .account-question {
      font-size: 17px;
      font-weight: 400;
      color: #242836;
      opacity: 0.5;
    }
    .line {
      flex-grow: 1;
      height: 2px; 
      color: #242836;
      opacity: 0.5;
    }
  }
  .form-btns-container {
    display: flex;
    column-gap: 16px;
  }
}

.reg-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 40px;
  .form-title {
    margin-bottom: 0;
    font-size: 17px;
    font-weight: 400;
    color: #131F6B;
  }
}

.register-content {
  .inputs-block {
    display: flex;
    column-gap: 32px;
    .block-item {
      display: flex;
      flex-direction: column;
      row-gap: 40px;
      width: 50%;
    }
  }
}

.entities-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 12px 60px 24px 60px;
  .title {
    color: #777777;
    font-weight: 400;
    font-size: 21px;
  }
  .form-button {
    width: 140px;
    height: 35px;
    margin-top: 16px;
    font-size: 12px;
    font-weight: 400;
    &:hover {
      color: #fff;
    }
  }
  .content-block {
    display: flex;
    flex-direction: column;
    background-color: #EFF4FB;
    padding: 8px 10px 18px 10px;
    row-gap: 8px;
    height: 368px;
    width: 554px;
    .search-block {
      display: flex;
      flex-direction: column;
      gap: 0;
      input {
        background: #fff;
      }
    }
  }
  .regular-text {
    font-size: 11px;
    font-weight: 400;
    color: #242836;
  }
}

.entity-search-block {
  input {
    background-color: #fff;
    width: 100%;
    &.has-icon-left {
      padding-left: 40px;
    }
  }
  .left-icon {
    left: 12px;
  }
}

.entity-items-block {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  overflow-y: auto;
  .entity-item {
    width: 127px;
    height: 88px;
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &.active {
      border: 1px solid #131F6B;
    }
    &:hover {
      border: 1px solid #131F6B;
    }
    .banner {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .no-img-banner {
      display: flex;
      flex-direction: column;
      gap: 4px;
      justify-content: center;
      align-items: center;
      .name {
        font-size: 14px;
        font-weight: 400;
      }
      .first-letter {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-color: #EFF4FB;
        color: #131F6B;
        font-size: 20px;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .no-result-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    row-gap: 12px;
    height: 200px;
    font-size: 22px;
    color: #777777;
    .btns-block {
      display: flex;
      column-gap: 16px;
      .cancel {
        background-color: #fff;
      }
    }
  }
}