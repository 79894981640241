@import '../common/animations'; 

.input-group {
  position: relative;
  border-bottom: 2px solid #d3d4d7;
  padding-bottom: 8px;
  margin: 5px 0 35px 0;
  display: flex;
  align-items: center;
  min-width: 300px;
  &.focus-bottom-border:focus-within {
    &::after {
      content: '';
      position: absolute;
      left: 50%;
      bottom: -3px;
      width: 100%;
      height: 2px;
      background-color: transparent;
      transform: scaleX(0);
      transform-origin: center;
      transition: background-color 0.3s, transform 0.3s;
    }
    &:focus-within::after {
      background-color: #131F6B;
      animation: focus-border-animation 0.3s forwards;
    }
  }
  input {
    border: none;
    background: transparent;
    outline: none;
    font-size: 17px;
    height: 23px;
    padding: 0 15px;
    &.pr-26 {
      padding-right: 26px;
    }
  }
  .icon-area {
    width: 50px;
    height: 23px;
    border-right: 2px solid #d3d4d7;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .right-icon-area {
    position: absolute;
    right: 0;
    top: 0;
    &.eye-icon-area {
      cursor: pointer;
      .eye-active-line {
        position: absolute;
        bottom: 11px;
        left: 0;
        height: 2px;
        width: 100%;
        background-color: #131F6B;
        transform: rotate(-45deg);
      }
    }
  }

  &.has-error {
    input {
      color: #ff7e93;
    }
  }

  &.no-icon {
    margin: initial;
    padding-bottom: 5px;
    input {
      width: 100%;
      height: 25px;
      padding-left: 0;
    }
  }
}
