.react-confirm-alert-body {
  &.md {
    width: 600px;
  }
  .link-txt {
    color: blue;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}