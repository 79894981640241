.header-section {
  font-weight: 100;
  min-height: 80px;
  -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.16);
  position: relative;
  &.has-pad {
    padding-right: 65px !important;
  }
}

.course-view {
  height: 100vh;

  .course-name {
    color: #000000;
    font-size: 20px;
    border-right: 2px solid #e4e4e4;
  }

  .course-authors {
    span {
      font-size: 17px;
      color: #868686;
    }
    svg {
      margin-right: 3px;
      path {
        fill: #868686;
      }
    }
    a {
      color: #4b569c;
    }
  }

  .body-section {
    padding-top: 3px;
    min-height: 470px;
    max-height: 470px;
    border-bottom: 2px solid #eff4fb;
  }

  .video-area {
    video {
      outline: none;
    }

    .video-react-big-play-button.video-react-big-play-button-center {
      width: 83px;
      height: 83px;
      border-radius: 100%;
      border-width: 1px;
      background: rgba(46, 48, 94, .65);
      &:before {
        font-size: 50px;
        line-height: 80px;
      }
    }
    .video-react-playing {
      .video-react-big-play-button.video-react-big-play-button-center {
        display: none;
      }
    }
  }

  .video-area {
    background-color: #000;
  }

  .quiz-area {
    &.preview {
      background-image: url('../../assets/backgroundImages/quiz_bg_big.png');
      background-repeat: no-repeat;
      background-size: cover;
    }

    .quiz-start-block {
        width: 100%;
        height: 100%;

      .inner-transparent-block {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        display: flex;
        gap: 24px;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .big-quiz-icon {
          margin-bottom: 22px;
        }
        .quiz-desc {
          color: #fff;
          max-width: 400px;
          text-align: center;
        }
      }
    }
  }

  .pdf-actions {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 58px;
    background-color: #F3F3F3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    padding: 0 12px 0 330px;
    &.max-100-230 {
      max-width: calc(100vw - 230px);
    }

    &.expanded {
      position: fixed;
      z-index: 99999999;
      width: calc(100% + 74px);
    }

    .document-actions, .zoom-actions {
      display: flex;
      align-items: center;
      gap: 12px;
    }

    .document-actions {
      margin-left: auto;
    }

    .pdf-action-icon {
      cursor: pointer;
    }
  }

  .course-parts {
    background: #fff;
    padding: 10px 25px 10px 10px;
    width: 310px;

    .course-title {
      padding: 5px 5px 2px;
      color: #2B2967;
      font-size: 14px;
      margin: 0;
      border-bottom: 2px solid #EFF4FB;
    }
  }

  .course-parts-lists {
    list-style-type: none;
    padding: 0;
    padding-top: 8px;
    height: 100%;
    overflow-y: auto;
    div.part-item {
      padding: 2px 0;
      border-bottom: 2px solid #eff4fb;
      min-height: 55px;
      cursor: pointer;
      &.active {
        background: #eff4fb;
        .time, .name {
          opacity: 1;
        }
      }
      &.disabled {
        cursor: not-allowed;
      }
      svg {
        width: 9px;
        height: 12px;
        path {
          fill: #131f6b;
        }
      }
      p {
        margin: 0;
      }
      &:not(.disabled) p {
        color: #242836;
      }
      &.disabled {
        p {
          color: #a7a7a7;
        }
        svg {
          path {
            fill: #a7a7a7;
          }
        }
      }
      .name {
        opacity: 0.8;
        font-size: 12px;
        font-weight: bold;
      }
      .time {
        font-size: 11px;
        opacity: 0.3;
      }
      &:nth-last-child(1) {
        border-bottom: none;
      }
    }
  }

  .footer-section {
    height: 100%;
    background: #fff;
    z-index: 100;
  }

  .info-area {
    font-weight: 200;
    max-width: 500px;
    h3 {
      font-weight: 200;
      font-size: 25px;
      color: #000000;
      margin-bottom: 0;
    }
    p {
      font-size: 17px;
      color: #868686;
    }
  }

  .courses-area {
    height: 100%;
    overflow-y: auto;
    min-height: 220px;
    padding-right: 10px !important;
    .list-item {
      padding: 8px 11px;
      max-height: 78px;
      border-bottom: 2px solid #eff4fb;
      p {
        color: #242836;
        font-size: 13px;
        margin: 0;
      }
      p.name {
        font-size: 17px;
        font-weight: bold;
      }
      p.description {
        // height: 40px;
        // width: 80%;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      img {
        width: 118px;
        // height: 64px;
      }
      svg {
        width: 13px;
        height: 17px;
        path {
          fill: #131f6b;
        }
      }
      &.active {
        position: relative;
        cursor: pointer;
        background: #eff4fb;
        border-right: 1px solid #949cc0;
        &:after {
          content: '';
          display: block;
          position: absolute;
          top: 31px;
          right: -8px;
          width: 15px;
          height: 15px;
          background: #eff4fb;
          border-right:1px solid #949cc0;
          border-bottom:1px solid #949cc0;
          transform:rotate(-45deg);
          -moz-transform:rotate(-45deg);
          -webkit-transform:rotate(-45deg);
        }
      }
      &.disable {
        cursor: not-allowed;
        opacity: 0.5;
      }
      .time-part {
        color:#242836;
        font-size: 11px;
        font-weight: bold;
        span {
          display: flex;
          align-items: center;
          gap: 2px;
        }
        svg {
          width: 13px;
          height: 10px;
        }
        &.quiz-time {
          svg {
            width: unset;
            height: unset;
          }
          span {
            align-items: flex-end;
          }
        }
        
      }
    }
  }

  .zoom-slider {
    width: 200px;
    margin-right: 1rem;
  }
}

.seek-Layout {
  position: absolute;
  height: 10px;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  transform-origin: center right;
  z-index: 1;
  cursor: not-allowed;
  background: rgba(255, 0, 0, 0.438);
}

.react-pdf__Document {
  height: 100%;
  overflow: auto;
}

.lesson-image-area {
  min-width: 120px;
  min-height: 74px;
}

.case-part {
  width: 100%;
  position: relative;
  min-height: 100%;
  overflow: hidden;
  background: #000;
  img {
    // opacity: 0.8;
    max-height: 465px;
  }
  .play-btn {
    position: absolute;
    width: 83px;
    height: 83px;
    border-radius: 100%;
    border-width: 1px;
    background: rgba(19, 31, 107, 0.72);
    z-index: 1;
    text-align: center;
    line-height: 83px;
    top: 50%;
    left: 50%;
    margin-top: -41.5px;
    margin-left: -41.5px;
    border: 1px solid #fff;
    svg {
      width: 27px;
      height: 28px;
      margin-left: 4px;
      path {
        fill: #fff;
      }
    }
  }
}

.footer-nav {
  background-color: #131f6b;
  color: #b5bef6;
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 5px;
  border-top-left-radius: 8px;
  z-index: 1000;
  font-size: 10px;
  a {
    text-decoration: none;
    color: #b5bef6;
  }
  svg {
    width: 13px;
  }
}


.course-info-progress {
  padding: 15px 10px;
  background: #EFF4FB;
  p {
    margin: 0;
    margin-top: 3px;
    font-size: 14px !important;
    color: #242836 !important;
  }
}

.mobile-marketing-lesson-name-container {
  background-color: #EEF4FC;
  padding: 36px 48px 30px 24px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  .name-block {
    display: flex;
    gap: 16px;
    .text-block {
      display: flex;
      flex-direction: column;
      gap: 18px;
      .title {
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 0;
      }
      .description {
        font-size: 14px;
        width: fit-content;
      }
    }
  }
}

.marketing-mobile-no-image {
  height: 200px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-video-player .video-react-big-play-button {
  height: 100px;
  border-radius: 50%;
  margin-top: -2.75rem !important;
  background-color: #131f6b;
  opacity: 0.6;
  &::before {
    top: 27px;
    left: 0;
    z-index: 99;
  }
}

.video-area {
  .mobile-img-sec {
    position: relative;
    width: 100%;
    height: 400px;
  }
}

.absolute-centered-video-play {
  position: absolute;
  top: calc(50% - 41.5px);
  left: calc(50% - 41.5px);
}

.lesson-thumbnail-container {
  width: 118px;
  height: fit-content;
  flex-shrink: 0;
  display: flex;
  align-items: center;
}

.lesson-row-block {
  gap: 10px;
}