.product-page-container {
  .btn-purple-filled {
    width: 124px;
    height: 50px;
  }
  .btn-need-help {
    width: fit-content;
    font-size: 12px;
    font-weight: 400;
    padding: 9px 24px;
  }
  .header {
    height: 115px;
    width: 100%;
    .top-sec {
      height: 36px;
      background-color: #F1F3F5;
      color: #333333;
      font-size: 14px;
      font-weight: 600;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      column-gap: 30px;
      padding: 0 16vw;
    }
    .bottom-sec {
      height: 78px;
      background-color: #fff;
      padding: 0 16vw;
      display: flex;
      align-items: center;
    }
  }
  .banner {
    width: 100%;
    height: 500px;
    display: flex;
    flex-wrap: wrap;
    .inner-block {
      position: relative;
      min-width: 400px;
      max-width: 50%;
      height: 100%;
      flex: 1;
      color: #fff;
      &.first-block {
        background: linear-gradient(90deg, #292B63 10.49%, #292B63 43.5%, #337C99 93.65%), linear-gradient(0deg, #D9D9D9, #D9D9D9);
        align-items: center;
        .content {
          position: absolute;
          left: 16vw;
          top: 100px;
        }
      }
    }
  }
  .specialty-section {
    display: flex;
    min-height: 450px;
    background-color: $bg-gray-main;
    padding: 56px 16vw 80px 16vw;
    column-gap: 128px;
    .inner-block {
      min-width: 400px;
      max-width: 50%;
      height: 100%;
      flex: 1;
      color: #333333;
      &.first-block {
        display: flex;
        flex-direction: column;
        row-gap: 30px;
      }
    }
    .slick-list { 
      height: unset;
      .img-cover {
        width: 100%;
        height: 294px;
      }
    }
    .slick-dots {
      position: absolute;
      top: 52%;
      right: -34px;
      height: auto;
      transform: translate(20%, -120%) rotate(90deg);
      display: block !important;
      li {
        margin: 10px 0;
        &.slick-active button:before {
          color: #AF3DB2;
        }
        button:before {
          font-size: 12px;
          color: #FFFFFF80;
          opacity: 1;
        }
      }
    }
  }
  .required-courses-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: $bg-gray-main;
    padding: 70px 16vw;

    .title-row {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 24px;
    }
    .course-progress-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      column-gap: 60px;
      .circle-item {
        position: relative;
        &:not(:last-child)::after {
          content: '';
          position: absolute;
          top: calc(50% - 20px);
          right: -32px;
          width: 80px;
          height: 0;
          border-top: 3px dashed #ACACAC;
          z-index: 0;
          transform: translate(50%, -50%);
        }
        &.active {
          &:not(:last-child)::after{
            border-color: $purple-main;
          }
        }
      }
    }
    .specialist-courses-sec {
      width: 100%;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 24px;
      padding: 23px 25px 34px 23px;

      .top-row {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .left-block {
          display: flex;
          width: fit-content;
          border-radius: 6px;
          overflow: hidden;

          .course-specialty-select-item {
            cursor: pointer;
            padding: 9px 16px;
            font-size: 18px;
            font-weight: 500;
            color: #131F6B;
            background-color: #F0F3F5;

            &.active {
              color: #fff;
              background-color: $purple-main;
            }
          }
        }

        .search-block {
          display: flex;
          column-gap: 24px;

          .search-types-block {
            display: flex;
            column-gap: 12px;
            align-items: center;

            .search-type-item {
              background-color: $bg-gray-dark;
              color: $primary-color;
              padding: 9px 12px;
              font-size: 10px;
              font-weight: 500;
              border-radius: 4px;
              display: flex;
              align-items: center;
              flex-shrink: 0;

              &.active {
                order: unset !important;
                color: #fff;
                background-color: $purple-main;
              }
            }
          }
        }
      }

      .product-courses-block {
        display: flex;
        column-gap: 20px;
        row-gap: 24px;
        flex-wrap: wrap;
      }

      .no-courses-block {
        display: flex;
        flex-direction: column;
        height: auto;
        align-items: center;
        row-gap: 8px;
        .no-result-img {
          width: 224px;
          height: auto;
          margin-bottom: 26px;
        }
        .desc {
          color: $primary-color;
          font-size: 20px;
          font-weight: 700;
          margin-bottom: unset;
          text-align: center;
        }
        .desc-2 {
          width: 300px;
          color: #4A5771;
          font-size: 16px;
          font-weight: 400;
          margin-bottom: unset;
          text-align: center;
        }
      }
    }
  }
  .page-cards-sec {
    display: flex;
    max-width: 100vw;
    width: 100%;
    justify-content: center;
    z-index: 1;
    position: relative;
    .slick-slider {
      width: 84%;
    }
    .slick-track {
      display: flex;
      column-gap: 16px;
      padding: 10px 0;
    }
    .slick-prev:before, .slick-next:before {
      font-size: 0;
    }
    .slick-disabled {
      opacity: 0.3;
    }
    .page-details-card {
      position: relative;
      width: 377px;
      height: 215px;
      box-shadow: 0px 0px 12px 0px #00000026;
      border-radius: 8px;
      padding: 16px;
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      background-color: #fff;
      cursor: pointer;
      text-decoration: none;
      &.active {
        background-color: #F1F3F5;
        box-shadow: unset;
      }
      &:hover {
        box-shadow: 0px 0px 12px 0px #00000026;
        background: #EFF4FB;
        transform: scale(1.01);
      }
      &.danger {
        border: 2px solid #E2574C;
      }
      .page-close-icon {
        position: absolute;
        top: -20px;
        right: -20px;
      }
      .bottom-part {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        row-gap: 8px;
        flex: 1;
        .desc {
          color: #333333;
          font-weight: 400;
          font-size: 14px;
        }
        .footer-part {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .footer-item {
            display: flex;
            column-gap: 4px;
            font-size: 14px;
            font-weight: 400;
              .key-name {
                color: #292B63;
              }
              .value {
                color: $purple-main;
                font-weight: 600;
              }
          }
        }
      }
      .head-part {
        display: flex;
        column-gap: 8px;
        align-items: center;
        .card-title {
          margin-bottom: unset;
          font-size: 18px;
          font-weight: 700;
          color: #292B63;
        }
        .card-img-container {
          width: 105px;
          height: 60px;
          overflow: hidden;
          border-radius: 0px 10px 10px 0px;
          .card-img {
            object-fit: cover;
          }
        }
      }
    }
  }
  .faq-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 70px 0;
    row-gap: 40px;
    .title-row {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      text-align: center;
    }
  }
  .footer-container {
    width: 100%;
    .top-part {
      padding: 0 16vw;
      width: 100%;
      height: 132px;
      display: flex;
      align-items: center;
      column-gap: 20px;
      color: #fff;
      background: linear-gradient(90deg, #292B63 1%, #061135 100%);

      .sec-1 {
        display: flex;
        flex-direction: column;
        row-gap: 12px;
      }
      .sec-2 {
        align-self: baseline;
        padding-top: 10px;
      }
    }
    .bottom-part {
      height: 122px;
      width: 100%;
      display: flex;
      .main-sec {
        padding: 0 16vw;
        height: 96px;
        display: flex;
        width: 100%;
        background-color: #F1F3F5;
        align-items: center;
        .texts-block {
          display: flex;
          align-items: center;
          column-gap: 24px;
          flex-wrap: wrap;
        }
      }
    }
  }

  .no-access-block {
    display: flex;
    padding: 40px 16vw 80px 16vw;
    justify-content: center;
    gap: 70px;
    .text-block {
      display: flex;
      flex-direction: column;
      justify-content: center;
      @include desktop {
        margin-left: 30px;
      }
      .line {
        width: 300px;
        height: 1px;
        background-color: #CACBE0;
        margin-top: 25px;
        margin-bottom: 32px;
      }
      .desc {
        font-size: 18px;
        font-weight: 400;
        color: #333333;
        margin-bottom: 20px;
        .bold {
          font-weight: 700;
        }
      }
    }
    .img-block {
      width: 520px;
      height: auto;
      @include desktop {
        margin-right: 30px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}

.section-title {
  color: #292B63;
  line-height: normal;
  font-size: 40px;
  font-weight: 800;
}

.section-title-dark-gray {
  color: #101828;
  font-weight: 600;
  font-size: 36px;
  &.sm {
    font-size: 20px;
    font-weight: 500;
  }
}

.sub-section-title {
  color: #292B63;
  font-weight: 800;
  font-size: 28px;
}

.section-desc {
  font-weight: 400;
  font-size: 18px;
  color: #333333;
  text-align: center;
}

.section-desc-gray {
  color: #667085;
  font-size: 20px;
  font-weight: 400;
  &.sm {
    font-size: 18px;
  }
}

.b-text {
  color: #333333;
  font-weight: 800;
  font-size: 18px;
}

.pages-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 50%;
    background-color: #F1F3F5;
  }
  &.empty {
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 12%;
      background-color: #F1F3F5;
    }
  }
  .page-container {
    width: 100%;
    margin-bottom: 40px;
  }
  .empty-block {
    margin-top: 34px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 24px;
    p {
      margin-bottom: unset;
    }
  }
}

.link-title-sec {
  position: relative;
  height: 220px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 28px 0;
  background-color: #fff;
  gap: 5px;
  .content {
    background: linear-gradient(90deg, #292B63 10.49%, #292B63 43.5%, #337C99 93.65%), linear-gradient(0deg, #D9D9D9, #D9D9D9);
    width: 100%;
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    * {
      text-align: center;
    }
    .desc {
      max-width: 400px;
      display: block;
      overflow: hidden;
      line-height: 15px;
      max-height: 150px;
      text-overflow: ellipsis;
      margin-bottom: unset;
    }
    .forward-icon {
      margin-top: 10px;
    }
  }
}

.prep-section {
  min-height: 946px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #F1F3F5;
  padding: 70px 16vw;
  row-gap: 40px;
  .title-block {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  }
  .circles-row-block {
    display: flex;
    justify-content: center;
    gap: 60px;
    margin-top: 20px;

    .circle-container {
      text-align: center;
      position: relative;

      &:not(:last-child)::after {
        content: '';
        position: absolute;
        top: calc(50% - 6px);
        width: 100%;
        height: 0;
        border-top: 2px dashed #ACACAC;
        z-index: 0;
        transform: translate(50%, -50%);
      }

      .circle {
        position: relative;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background-size: cover;
        display: inline-block;
        z-index: 1;
      }
      .age-range {
        font-weight: 400;
        display: block;
        margin-top: 8px;
        font-size: 14px;
        color: #333;
      }
      &.selected {
        .circle {
          border: 3px solid $purple-main;
        }
        .age-range {
          font-weight: 800;
          color: $purple-main;
        }
      }
    }
  }
  .card-block {
    display: flex;
    column-gap: 100px;
    padding: 40px;
    background-color: #FFFFFF;

    .info-block {
      display: flex;
      flex-direction: column;
      row-gap: 26px;
      .row-texts-block {
        display: flex;
        flex-direction: column;
        padding-left: 20px;
        row-gap: 6px;
      }
    }

    .img-block {
      width: 30%;
      height: auto;
      flex-shrink: 0;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}

.regular-text-sm {
  font-size: 10px;
  font-weight: 400;
}

.landing-course-item {
  width: 295px;
  height: 312px;
  color: black !important;
  text-decoration: none;
  .img_block {
    position: relative;
    overflow: hidden;
    border-radius: 5px;
  }
  .title-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 4px;
    margin-bottom: 6px;
    .course-title {
      color: #13387C;
      font-weight: 700;
      font-size: 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 150px;
    }
    .create-date {
      color: #4A5771;
      font-weight: 400;
      font-size: 13px;
    }
  }
  .course-description {
    color: #4A5771;
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .img_block {
    position: relative;
    width: 100%;
    height: 205px;
    background-color: #ffffff;
    .course-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .linear-progress-block {
    background-color: #fff;
    padding: 6px 8px;
    border-radius: 8px;
    position: absolute;
    left: 8px;
    bottom: 8px;
    width: calc(100% - 16px);

    .progress-item {
      height: 8px;
      border-radius: 8px;
      background-color: #F1F3F5;

      > div {
        background-color: #A245AD;
      }
    }
  }
}

.product-entities-block {
  max-height: 100%;
  width: 100%;
  display: flex;
  gap: 8px;
  padding: 8px;
  background-color: $bg-primary;
  border-radius: 5px;
  overflow-y: auto;
  .entity-item {
    width: 264px;
    height: 100px;
    padding: 12px 16px 24px 16px;
    border-radius: 5px;
    gap: 8px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    &:hover {
      outline: 2px solid $primary-color;
    }

    &.active {
      outline: 2px solid $primary-color;
    }

    .img-block {
      width: 90px;
      height: 28px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .title-as-icon-block {
      font-size: 26px;
      font-weight: 700;
    }
    .name {
      color: #222222;
    }
  }
}