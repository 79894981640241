.question-submit-sec {
  background-color: #F9FAFB;
  margin: 0 16vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 32px;
  padding: 32px 0 50px 0;
  margin-bottom: 66px;

  .title-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    row-gap: 8px;
    margin-bottom: 4px;
  }
  .form-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 24px;

    .inputs-row {
      display: flex;
      column-gap: 32px;
    }
  }
}