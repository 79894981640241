.redesigned-select-container {
  border-bottom: none;
  &.outlined-gray {
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 6px;
    width: 100%;

    label {
      color: $gray-700;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 0;
    }
    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin-left: 1px;
      padding: 12px 16px 12px 12px;
      align-self: center;
      border-radius: 8px;
      background-color: #fff;
      border: 1px solid $gray-300;
      box-shadow: 0px 1px 2px 0px #1018280D;
      width: 100%;
      height: 48px;
      background-image: url('../../assets/icons/select-arrow-down.svg');
      background-repeat: no-repeat;
      background-position: right 12px center;
      background-size: 12px;
    }
    select:focus {
      outline: none;
    }
  }
}

.redesigned-select-purple-filled {
  position: relative;
  border-bottom: none;
  display: flex;
  align-items: center;
  color: #fff;

  .select-wrapper {
    position: relative;
    width: 100%;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 6px 12px;
    align-self: center;
    border-radius: 8px;
    border: none;
    background-color: $purple-main;
    color: #F0F3F5 !important;
    box-shadow: 0px 1px 2px 0px #1018280D;
    width: 100%;
    height: 38px;
  }

  .select-icon {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    background-image: url('../../assets/icons/select-arrow-down-white.svg');
    background-repeat: no-repeat;
    background-size: contain;
    margin-left: 8px;
    pointer-events: none;
    transition: transform 0.3s ease;

    &.hasLeftLine {
      &::before {
        content: '';
        position: absolute;
        left: -8px; /* Position the border 8px to the left of the icon */
        top: 0;
        height: 100%;
        border-left: 1px solid #C493CA;
      }
    }
  }

  &.md {
    .select-wrapper select {
      width: 136px;
    }
  }

  label {
    color: #F0F3F5;
    font-size: 10px;
    font-weight: 500;
    margin-bottom: 0;
  }

  select:focus {
    outline: none;
  }
}
