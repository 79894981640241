$alertSuccess: #155724;
$alertSuccessBackgroundColor: #d4edda;
$alertSuccessBorderColor: #c3e6cb;
$alertWarningLight: #856404;
$alertWarningLightBackgroundColor: #fff3cd;
$alertWarningLightBorderColor: #ffeeba;
$alertWarning: #7d5f03;
$alertWarningBackgroundColor: #ffd757;
$alertWarningBorderColor: #dea706;
$alertDanger: #721c24;
$alertDangerBackgroundColor: #f8d7da;
$alertDangerBorderColor: #f5c6cb;


.alert {
  border-radius: 6px;
  border: 1px solid #ededed;
  background-color: #fff;
  padding: 10px 15px;
  margin: 0;

  &-success {
    color: $alertSuccess;
    border-color: $alertSuccessBorderColor;
    background-color: $alertSuccessBackgroundColor;
  }
  &-warning-light {
    color: $alertWarningLight;
    border-color: $alertWarningLightBorderColor;
    background-color: $alertWarningLightBackgroundColor;
  }
  &-warning {
    color: $alertWarning;
    border-color: $alertWarningBorderColor;
    background-color: $alertWarningBackgroundColor;
  }
  &-danger {
    color: $alertDanger;
    border-color: $alertDangerBorderColor;
    background-color: $alertDangerBackgroundColor;
  }
}
