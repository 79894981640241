.subscription-item {
  height: 82px;
  background: #eff4fb;
  margin-bottom: 10px;
  border-radius: 5px;
  overflow: hidden;

  > div {
    height: 100%;
  }

  img {
    width: 153px;
    object-fit: cover;
    object-position: top;
  }

  h4 {
    font-size: 17px;
    color: #242836;
    font-weight: 500;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    li {
      color: #242836;
      font-size: 13px;
      border-left: 1px solid #c6cbd4;
      padding: 0 20px;
      a {
        color: #131f6b;
        text-decoration: none;
        font-weight: 500;
      }
      &:nth-child(1) {
        padding-left: 0;
        border-left: 0;
      }
    }
  }
  .user-subscibe-info {
    font-size: 13px;
    color: #242836;
  }

  .subs-item-image {
    position: relative;
    .lock-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(255,255,255,0.5);
      svg {
        width: 12px;
      }
    }
  }
}

.subs-type {
  span {
    display: inline-block;
    height: 20px;
    background: #fff;
    padding: 0 10px;
    font-weight: bold !important;
  }
}

.subscribe-btn {
  font-size: 13px;
  color: #131f6b;
  font-weight: 500;
}

.subscribe-btn-block {
  width: 112px;
  height: 35px;
  background: #131F6B;
  font-size: 13px;
  color: #fff;
  font-weight: 500;
  text-align: center;
  &:hover {
    background: #131F6B;
    color: #fff;
  }
}

.accordion {
  .part-item {
    background: #eff4fb;
    margin-bottom: 10px;
    border-radius: 5px;
    overflow: hidden;
    font-size: 14px;
    color: #222222;
  }

  button:focus {
    box-shadow: none;
  }

  .faq-item-body {
    font-weight: 100;
  }
}

.plan-info-box {
  display: flex;
  flex-direction: column;
  background: #eff4fb;
  padding: 25px 15px;
  border-radius: 5px;
  color: #242836;
  img {
    width: 100%;
  }
  p {
    font-size: 17px;
    font-weight: 500;
  }
  span {
    font-size: 13px;
    font-weight: 100;
  }
}

.subscription-image {
  width: 100%;
  height: 160px;
  object-position: center;
  object-fit: cover;
}
