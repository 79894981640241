.temp-selected-area {
  position: absolute;
  display: flex;
  align-items: center;
  left: 0px;
  top: 2px;
  background-color: black;
  z-index: 99;
  width: 100%;
  height: calc(100% - 4px);
  border-radius: 9px;
  outline: 1px solid #707070;
  overflow: hidden;
  .img-part-block {
    position: relative;
    height: fit-content;
    .dicom-img {
      width: 100%;
      height: auto;
    }
  }
  .btn-switch-to {
    position: absolute;
    bottom: 18px;
    right: 12px;
    background-color: #C8C8C8;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 400;
    color: #000;
    padding: 8px 15px;
    z-index: 99;
  }
  .point-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 11px;
    width: 80px;
    height: 100px;
    border: 2px dashed #E04300;
    position: absolute;
    z-index: 999;
    
    .shape-icon {
      position: absolute;
      top: calc(50% - 12px);
      left: calc(50% - 12px);
      align-self: center;
    }
    .details-block {
      position: absolute;
      top: calc(50% + 16px);
      left: calc(50% - 18px);
      display: flex;
      gap: 4px;

      .designator {
        width: 19px;
        height: 19px;
        color: #000;
        background-color: #C8C8C8;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        font-weight: 900;
      }
      .percentage {
        font-size: 400;
        font-weight: 12px;
        color: #C8C8C8;
      }
    }
  }
}

.associations-switcher {
  display: flex;
  align-items: center;
  gap: 16px;
  position: absolute;
  bottom: 40px;
  left: 12px;
  background-color: #212121;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 400;
  color: #B1B1B1;
  padding: 8px 16px;
  z-index: 999;

  .vector-btn {
    cursor: pointer;
  }
}