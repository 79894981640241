.question-container {
  width: 100%;
  padding: 12px 25px 12px 16px;

  &.result-container {
    width: calc(100% - 320px);
  }

  .question-block {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    border: 2px solid #8089B3;
    border-radius: 8px;
    padding-bottom: 24px;
    padding-right: 2px;

    &.success {
      .body {
        display: flex;
        flex-direction: column;
        row-gap: 44px;
        align-items: center;
        padding: 42px 20px 0 20px;

        .details-block {
          display: flex;
          column-gap: 34px;
          font-size: 14px;

          .detail-item {
            font-weight: 400;
            color: #777777;

            .value {
              color: #131F6B;
              font-weight: 700;

              &.danger {
                color: #FB7575;
              }
            }
          }
        }
        .congrats-block {
          display: flex;
          flex-direction: column;
          align-items: center;
          color: #222222;
          font-size: 24px;
          font-weight: 400;

          .congrats {
            font-size: 32px;
            color: #2DA41A;
          }
          .oops {
            font-size: 36px;
            color: #FA5252;
          }
        }
      }
    }

    p {
      margin-bottom: unset;
    }

    .question-head {
      display: flex;
      gap: 22px;
      width: 100%;
      min-height: 86px;
      background-color: #EFF4FB;
      color: #2B2D42;
      padding: 10px 32px 16px 38px;
      font-size: 16px;
      font-weight: 500;

      &.success {
        justify-content: center;
        align-items: center;
        .title {
          font-size: 46px;
          font-weight: 500;
          color: #2B2D42;
          margin-bottom: 0;
        }
      }

      .q-number-in-all {
        font-size: 46px;
        margin-left: auto;
        line-height: normal;
        
        .success {
          color: #2DA41A;
        };
        .failed {
          color: #FA5252;
        };
        .skipped {
          color: #ffffff;
        }
      }
      .q-description {
        margin: 10px 0;
      }
      .q-number {
        margin-top: 11px;
      }
    }
    .question-body {
      position: relative;
      width: 100%;
      height: 100%;
      padding-left: 40px;
      .expired-question-indication {
        position: absolute;
        top: 0;
        right: 40px;
        height: 34px;
        width: 96px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #FA5252;
        color: #ffffff;
        font-size: 20px;
        font-weight: 500;
        border-radius: 0 0 100px 100px;
      }
    }
    .answer-btns-block {
      width: 100%;
      display: flex;
      justify-content: center;
      gap: 16px;
      margin-top: auto;
    }
    .btn-quiz-main {
      font-size: 16px;
      font-weight: 700;
      color: #131F6B;
      padding: 8px 24px;

      &.what-wrong {
        max-width: 202px;
        align-self: center;
        margin-top: 24px !important;
      }
    }
    .btn-quiz-outlined-white {
      font-size: 16px;
      font-weight: 700;
      color: #131F6B;
      border: 1px solid #131F6B;
      background-color: #fff;
      padding: 8px 24px;
    }
  }
}

.answers-content {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  padding: 32px 72px 10px 72px;

  &.image-type {
    padding: 8px 22px 0 60px;
    row-gap: 12px;
  }

  .type-block {
    font-size: 14px;
    font-weight: 700;
    color: #777777;

    .color-primary {
      color: #131F6B;
    }
  }
  .answers-block {
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    .answer-row {
      display: flex;
      column-gap: 10px;

      .answer-checkbox {
        position: absolute;
        opacity: 0;

      }
      .custom-checkbox {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: #EFF3FC;

        .answer-check {
          position: absolute;
          top: -5px;
          right: -2px;
        }
      }

      .answer-radio {
        position: absolute;
        opacity: 0;
        &:checked + .answer-text .custom-radio {
          border: 2px solid #131F6B;
        }
        &:checked + .answer-text .custom-radio::before {
          background: #131F6B;
        }
      }

      .answer-text {
        position: relative;
        padding-left: 32px;
        font-size: 16px;
        font-weight: 400;
        color: #222222;
        margin-bottom: 0;

        .custom-radio {
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 24px;
          height: 24px;
          border-radius: 50%;
          background-color: #EFF3FC;
    
          &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 14px;
            height: 14px;
            border-radius: 50%;
            background: transparent;
          }
        }
      }
    }
  }
}

.custom-scrollbar-block {
  position: relative;
  &.horizontal {
    padding-right: 16px;
  }
  &::-webkit-scrollbar {
    position: absolute;
    width: 10px;
    height: 10px;
    margin-top: 16px;
    margin-left: 16px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    width: 10px;
    height: 10px;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #131F6B;
    border-radius: 5px;
    height: 10px;
    width: 10px;
  }
}

.images-columns-container {
  display: flex;
  gap: 8px;
  width: 100%;
  overflow-x: auto;
  padding-bottom: 20px;
  padding-top: 10px;
} 

.quiz_view_img_column_container {
  width: 196px;
  height: 194px;
  border-radius: 6px;
  overflow: hidden;
  flex-shrink: 0;

  &.wide {
    width: 284px;
  }

  .head_block {
    width: 100%;
    height: 35px;
    display: flex;
    align-items: center;
    padding-left: 16px;
    background-color: #131F6B;
    color: #fff;
  }
  .body_block {
    width: 100%;
    height: calc(100% - 35px);
    background-color: #fff;
    padding: 14px;
    border: 1px solid #EBEBEB;
    .scrollable_block {
      width: 100%;
      max-height: 100%;
      display: flex;
      flex-wrap: wrap;
      column-gap: 6px;
      row-gap: 8px;
      overflow-y: auto;
      .img_item {
        position: relative;
        width: 80px;
        height: 56px;
        border-radius: 6px;
        border: 1px solid #EBEBEB;
        overflow: hidden;
        &.selected {
          border-color: #131F6B;
        }
        cursor: pointer;
          .overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            &.success {
              background: rgba(45, 164, 26, 0.6);
            }
            &.danger {
              background: rgba(235, 77, 77, 0.6);
            }
          }
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}

.custom-scrollbar-block {
  position: relative;
  &::-webkit-scrollbar {
    position: absolute;
    height: 10px;
    margin-top: 16px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    height: 10px;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #131F6B;
    border-radius: 5px;
    height: 10px;
  }
}