.hover-course-item {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 9;
  width: 280px;
  height: 121px;
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  padding: 8px;
  column-gap: 8px;
  overflow-y: hidden;
  .banner {
    position: relative;
    height: 100%;
    width: 147px;
    flex-shrink: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .play-icon {
      position: absolute;
      top: calc(50% - 20px);
      left: calc(50% - 20px);
    }
  }
  .title {
    font-size: 16px;
    font-weight: 700;
    color: #13387C;
    max-width: 100px;
    margin-bottom: unset;
    overflow: hidden;
  }
}