.app-dropdown {
  position: relative;

  .app-dropdown-header {
    cursor: pointer;
    padding-right: 20px;
    position: relative;

    &::before,
    &::after {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      position: absolute;
      right: 7px;
      transform: rotate(45deg);
    }

    &::before {
      background: #fff;
      top: 4px;
      z-index: 2;
    }
    &::after {
      background: #131f6b;
      top: 6px;
      z-index: 1;
    }
  }

  &.active .app-dropdown-header {
    &::before {
      top: 13px;
    }
    &::after {
      top: 11px;
    }
  }

  .app-dropdown-body {
    width: 400px;
    max-height: 345px;
    background: #fff;
    box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    margin-top: 10px;
    padding: 15px;
    overflow: hidden;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
  }

  &.to-left .app-dropdown-body {
      left: unset;
      right: 0;
    }

  &.to-top .app-dropdown-body {
    top: unset;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 10px;
  }

  ul {
    list-style: none;
    margin: 0;
    height: 100%;
    overflow: auto;

    li:not(.disabled):hover {
      background: #eff4fb;
    }
  }

  a {
    text-decoration: none;
  }
}

// Specific
.authors-dropdown {
  padding: 0;

  .drop-item {
    padding: 10px 10px;

    &:not(:last-child) {
      border-bottom: 2px solid #eff4fb;
    }

    .author-image {
      width: 80px;
      height: 80px;
      margin-right: 12px;
      border-radius: 100%;
      border: 1px solid #ededed;
      overflow: hidden;
      text-align: center;
    }

    img {
      min-width: 80px;
      height: 100%;
      max-width: inherit;
    }
  }

  .item-name {
    font-weight: 600;
    color: #131f6b !important;
  }

  .item-description {
    font-weight: 300;
    font-size: 12px;
  }
}
