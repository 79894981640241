.service-and-maintenance-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .service-img {
    width: 535px;
  }
}