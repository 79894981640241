.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(5px); // This will give the blurred background effect
  z-index: 999;
  
  .modal-content {
    background-color: white;
    border-radius: 8px;
    width: 900px;
    min-height: 600px;
    position: relative;
    padding: 24px 24px 40px 56px;

    &.offline-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;
      padding: 0;

      .offline-warning {
        font-size: 60px;
        color: #EB4D4D;
      }
      .offline-description {
        font-size: 22px;
        color: #222222;
      }
      .offline-countdown {
        font-size: 160px;
        font-weight: 700;
        color: #EB4D4D;
      }
    }

    .close-button {
      position: absolute;
      top: 0;
      right: 0;
      background: none;
      border: none;
      cursor: pointer;
    }

    .modal-body {
      text-align: center;
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      padding-right: 30px;

      .main-desc {
        font-size: 16px;
        font-weight: 400;
        color: #242836;
        text-align: center;
        max-width: 460px;
      }

      .content {
        width: 100%;
        height: 336px;
        border-bottom: 2px solid #EFF4FB;
        border-top: 2px solid #EFF4FB;
        color: #242836;
        text-align: start;

        .title {
          font-size: 20px;
          font-weight: 700;
        }
        .description {
          font-size: 16px;
          font-weight: 400;
        }
        .first-block {
          border-bottom: 2px solid #EFF4FB;
          padding: 16px 0;
        }
        .second-block {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
        .details-block {
          display: flex;
          align-self: center;
          gap: 40px;
          min-width: 700px;
          justify-content: space-between;

          .vertical-border {
            width: 2px;
            height: 80px;
            background: #EFF4FB;
          }

          .right-block, .left-block {
            width: 48%;
          }

          .row {
            width: 100%;
            display: flex;
            justify-content: space-between;

            &.danger {
              color: #EB4D4D;
            }

            .name-part {
              display: flex;
              gap: 8px;
            }
          }
        }
      }

      .btns-container {
        width: 100%;
          display: flex;
          justify-content: flex-end;
          gap: 16px;

        .btn-start {
          border-radius: 5px;
          width: 120px;
          color: #131F6B;
          font-size: 16px;
          font-weight: 700;
          background-color: #EFF3FC;
        }
        .btn-cancel {
          border-radius: 5px;
          width: 98px;
          border: 1px solid #707070;
          color: #707070;
          background: transparent;
        }
      }
    }
  }
}
