.associations-dialog {
  position: absolute;
  width: 345px;
  max-height: 559px;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  top: 42px;
  right: 119px;
  padding: 16px;
  border-radius: 10px;
  border: 1px solid #707070;
  background-color: #212121;
  z-index: 99;
  row-gap: 10px;

  &.empty {
    justify-content: center;
    align-items: center;

    .empty-content {
      height: 110px;
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 24px;
    }
  }

 .title-row {
  font-size: 12px;
  font-weight: 400;
  color: $gaid-shape-active;
  display: flex;
  justify-content: space-between;
  .close-icon-block {
    cursor: pointer;
  }
  .details-row {
    display: flex;
    align-items: center;
    column-gap: 4px;
    & > span {
      display: flex;
      align-items: center;
    }
  }
  .designator {
    font-size: 16px;
    font-weight: 600;
    margin-left: 4px;
  }
  .case-name {
    font-size: 12px;
    font-weight: 400;
    color: $gaid-text-primary-color;
  }
 }
}

.associations-container {
  display: flex;
  flex-direction: column;
  overflow: auto;
  row-gap: 10px;
}

.empty-associations {
  margin: auto;
  font-size: 22px;
  color: #707070;
}

.association-item {
  display: flex;
  background-color: #000;
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
  .drag-icon-block {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .content-block {
    color: #A4A4A4;
    
    .dcm-name {
      margin-bottom: 5px; 
      font-weight: bold;
      line-break: anywhere;
    }
    .row-1 {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #2D2D2D;
      margin-bottom: 10px;
      padding-bottom: 10px;
    }
    .row-2 {
      display: flex;
      justify-content: space-between;
    }
  }
  &.active {
    background-color: #C8C8C8;
    color: #000;
    * {
      color: #000;
    }
    .row-1 {
      border-color: #000;
    }
  }
}