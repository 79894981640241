// Breakpoints
$phone: 600px;
$tablet: 1024px;

// Colors

$gray-50: #F9FAFB;
$gray-300: #D0D5DD;
$gray-500: #667085;
$gray-700: #344054;
$purple-main: #AF3DB2;
$bg-gray-main: #F1F3F5;
$bg-gray-dark: #F0F3F5;
$bg-primary: #EFF4FB;
$text-main-color: #2B2967;
$primary-color: #131F6B;
$gaid-shape-active: #EDD500;
$gaid-text-primary-color: #A4A4A4;

// Border radiuses

$radius-input-main: 8px;