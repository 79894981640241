.dashboard-header {
  position: relative;
  height: 70px;
  background: #131F6B;
  padding: 10px 60px;
  .dashboard-nav {
    ul {
      list-style-type: none;
      li {
        margin-left: 40px;
        &.is-big-nav {
          a {
            font-size: 25px;
            color: #FFFFFF;
            font-weight: 400;
          }
        }
      }
      a, .drop-link {
        color: #F6F6F6;
        font-size: 15px;
        font-weight: 200;
        line-height: 24px;
        &:hover {
          text-decoration: none;
        }
        &.active {
          font-weight: 400;
        }
        .drop-link-menu {
          box-shadow: 0px 0px 9px #00000017;
          min-width: 92px;
          right: 0;
          li {
            padding: 0 13px;
            a {
              padding: 8px 0;
              font-weight: 400;
              font-size: 12px;
              color: #181818;
              border-bottom: 1px solid #EFF4FB;
              line-height: 16px;
              &:hover {
                background: none;
              }
            }
            &:nth-last-child(1) {
              a {
                border: none;
              }
            }
            &:hover {
              background: #F0F0F0;
            }
          }
        }
      }
    }
  }
  .drop-account span {
    display: block;
    text-align: center;
    color: #B5BEF6;
    line-height: 18px;
    font-weight: 400;
    margin: 0 14px;
  }
  .avatar {
    img {
      height: 40px;
      border-radius: 100%;
    }
  }
}
